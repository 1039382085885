import type { Customer, JobGroup, StoredObject } from '@nexdynamic/squeegee-common';
import {
    Expense,
    ExpenseCategory,
    FrequencyType,
    Invoice,
    Job,
    JobOccurrence,
    JobOccurrenceStatus,
    Location,
    Service,
    SyncMode,
    Tag,
    TagType,
    Transaction,
    TransactionType,
    sortByCreatedDateAsc,
    sortByCreatedDateDesc,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { parse } from 'papaparse';
import { ApplicationState } from '../../ApplicationState';
import { CustomerService } from '../../Customers/CustomerService';
import { Prompt } from '../../Dialogs/Prompt';
import { TextDialog } from '../../Dialogs/TextDialog';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import { Api } from '../../Server/Api';
import { Utilities } from '../../Utilities';
import { Data } from '../Data';
import { ImportValidator } from '../ImportValidator';
import type { CleanerPlannerCommonEntity } from './CleanerPlannerCommonEntity';
import { CleanerPlannerContact } from './CleanerPlannerContact';
import { CleanerPlannerCustomer } from './CleanerPlannerCustomer';
import { CleanerPlannerCustomerSource } from './CleanerPlannerCustomerSource';
import { CleanerPlannerExpenseCategory } from './CleanerPlannerExpenseCategory';
import { CleanerPlannerInvoice } from './CleanerPlannerInvoice';
import { CleanerPlannerJob } from './CleanerPlannerJob';
import { CleanerPlannerNote } from './CleanerPlannerNote';
import { CleanerPlannerRound } from './CleanerPlannerRound';
import { CleanerPlannerService } from './CleanerPlannerService';
import type { CleanerPlannerTagEntity } from './CleanerPlannerTagEntity';
import { CleanerPlannerTransaction } from './CleanerPlannerTransaction';

export class CleanerPlannerImportResolver {
    public static async createAutoImport(email: string, password: string) {
        new LoaderEvent(false);
        const serviceDialog = new TextDialog('services.default-service-name', 'services.enter-service', 'default', '', value =>
            !value ? 'services.enter-service' : true
        );
        const defaultServiceName = await serviceDialog.show();
        if (serviceDialog.cancelled) return;
        new LoaderEvent(true);

        const result = await Api.post<{ success: boolean; error: string; files: { [filename: string]: string } }>(
            Api.apiEndpoint,
            '/api/import/cp',
            { email, password },
            false,
            false
        );
        if (!result || !result.data) throw 'Failed to get the Cleaner Planner download automatically, request failed.';

        const data: { success: boolean; error: string; files: { [filename: string]: string } } | undefined = result && result.data;

        if (!data) throw 'Unable to read the Cleaner Planner zip file.';

        if (!data.success) throw data.error || 'Unable to read the Cleaner Planner zip file.';

        return new CleanerPlannerImportResolver(data.files, defaultServiceName);
    }

    public static async createImportFromZip(zip: File) {
        new LoaderEvent(false);
        const serviceDialog = new TextDialog('services.default-service-name', 'services.enter-service', 'default', '', value =>
            !value ? 'services.enter-service' : true
        );
        const defaultServiceName = await serviceDialog.show();
        if (serviceDialog.cancelled) return;
        new LoaderEvent(true);

        const result = await Api.postFile(Api.apiEndpoint, '/api/import/cp/fromzip', zip);
        if (!result || !result.data) throw 'Failed to get the Cleaner Planner download automatically, request failed.';

        const data = result.data as { success: boolean; error: string; files: { [filename: string]: string } } | undefined;

        if (!data) throw 'Unable to read the Cleaner Planner zip file.';

        if (!data.success) throw data.error || 'Unable to read the Cleaner Planner zip file.';

        return new CleanerPlannerImportResolver(data.files, defaultServiceName);
    }

    private constructor(private files: Array<File> | { [filename: string]: string }, private defaultServiceName: string) {
        if (Array.isArray(files) && !files.length) throw 'No files passed to the Cleaner Planner import resolver';
    }

    private deleteExistingData: boolean;
    private readonly warnings: Array<string> = [];
    public async doFullImport(deleteExistingData: boolean) {
        this.deleteExistingData = deleteExistingData;
        try {
            new LoaderEvent(true);

            const expenseCategories = await this.getData('expensecategories.csv', new CleanerPlannerExpenseCategory());
            const services = await this.getData('services.csv', new CleanerPlannerService());
            const rounds = await this.getData('rounds.csv', new CleanerPlannerRound());
            const contacts = await this.getData('contacts.csv', new CleanerPlannerContact());
            const customers = await this.getData('customers.csv', new CleanerPlannerCustomer());
            const customerSources = await this.getData('customersources.csv', new CleanerPlannerCustomerSource());
            // const invoices = await this.getData('invoices.csv', new CleanerPlannerInvoice());
            const jobs = await this.getData('jobs.csv', new CleanerPlannerJob());
            const notes = await this.getData('notes.csv', new CleanerPlannerNote());
            //const transactions = await this.getData('transactions.csv', new CleanerPlannerTransaction());

            if (this.deleteExistingData) {
                Logger.info('Deleting existing data');
                await Data.deleteAllData(false);
            }

            const existingTags = Data.all<Tag>('tags').slice();

            const expenseCategoryTags: Array<Tag> = [];

            for (const expenseCategory of expenseCategories)
                expenseCategoryTags.push(this.createTagEntity(expenseCategory, TagType.EXPENSE_CATEGORY, existingTags));
            const generalExpenseCategory = new ExpenseCategory('General');
            expenseCategoryTags.push(generalExpenseCategory);

            const serviceTags: Array<Tag> = [];

            for (const s of services) serviceTags.push(this.createTagEntity(s, TagType.SERVICE, existingTags));
            let generalService = Data.firstOrDefault<Tag>(
                'tags',
                s => s.type === TagType.SERVICE && s.description === this.defaultServiceName
            );
            if (!generalService) generalService = new Service(this.defaultServiceName);

            serviceTags.push(generalService);

            const roundTags: Array<JobGroup> = [];
            for (const r of rounds) roundTags.push(this.createTagEntity(r, TagType.ROUND, existingTags) as JobGroup);

            const squeegeeCustomersAndInitialBalances = await this.createSqueegeeCustomers(
                contacts,
                customers,
                jobs,
                customerSources,
                roundTags,
                serviceTags,
                notes,
                generalService
            );

            Logger.info(<any>squeegeeCustomersAndInitialBalances);

            let allData: Array<StoredObject> = [];

            allData = allData.concat(expenseCategoryTags).concat(serviceTags).concat(roundTags).concat(squeegeeCustomersAndInitialBalances);

            const now = moment();
            const isoUpdatedDate = now.format();
            const timestamp = now.valueOf();

            allData.forEach(x => Data.updateStoredObjectMetaData(x, isoUpdatedDate, timestamp));

            await Data.put(allData, false, 'lazy');
            await Data.fullSync(SyncMode.Partial);
            await this.showImportCompleteDialog();
        } catch (error) {
            new LoaderEvent(false);
            new Prompt(
                'prompts.error-title',
                <any>(ApplicationState.localise('import.cleaner-planner-full-error') + `${typeof error === 'string' ? ': ' + error : ''}`),
                { cancelLabel: '' }
            ).show();
            Logger.error(`Error during doFullImport from cleaner planner`, error);
        }
        new LoaderEvent(false);
    }
    public async doHistoricImport(cutOff: string) {
        try {
            new LoaderEvent(true);

            const invoices = await this.getData('invoices.csv', new CleanerPlannerInvoice());
            const transactions = await this.getData('transactions.csv', new CleanerPlannerTransaction());

            const existingTags = Data.all<Tag>('tags');
            const expenseCategoryTags: Array<Tag> = existingTags.filter((x: Tag) => {
                return x.type === TagType.EXPENSE_CATEGORY;
            });

            const generalExpenseCategory =
                Data.firstOrDefault<Tag>('tags', s => s.type === TagType.SERVICE && s.description === 'General') ||
                new Tag('General', TagType.EXPENSE_CATEGORY);
            const customersOnly = CustomerService.getCustomers();

            // Delete any existing balancing transactions.

            const existingBalances = Data.all<Transaction>(
                'transactions',
                x => x.transactionSubType === 'adjustment.initial-balance-imported'
            );

            const existingHistoricTrans = await Data.all<Transaction>('transactions', x => !!x._externalId);

            const squeegeeTransactions = await this.createTransactions(
                transactions,
                expenseCategoryTags,
                customersOnly,
                invoices,
                generalExpenseCategory,
                cutOff
            );

            const nonInvoices = squeegeeTransactions.filter(x => x.transactionType !== TransactionType.Invoice);
            const squeegeeInvoices = squeegeeTransactions
                .filter(x => x.transactionType === TransactionType.Invoice)
                .sort(sortByCreatedDateDesc);

            let allData: Array<StoredObject> = [];

            allData = allData.concat(nonInvoices).concat(squeegeeInvoices);

            const now = moment();
            const isoUpdatedDate = now.format();
            const timestamp = now.valueOf();

            allData.forEach(x => Data.updateStoredObjectMetaData(x, isoUpdatedDate, timestamp));

            await Data.put(allData, false, 'lazy');

            await Data.delete(existingBalances as unknown as Array<StoredObject>);
            await Data.delete(existingHistoricTrans as unknown as Array<StoredObject>);
            await Data.fullSync(SyncMode.Partial);

            await this.showImportCompleteDialog();
        } catch (error) {
            new LoaderEvent(false);
            new Prompt(
                'prompts.error-title',
                <any>(ApplicationState.localise('import.cleaner-planner-full-error') + `${typeof error === 'string' ? ': ' + error : ''}`),
                { cancelLabel: '' }
            ).show();
            Logger.error(`Error during doFullImport from cleaner planner`, error);
        }

        new LoaderEvent(false);
    }
    private async createTransactions(
        cpTransactions: Array<CleanerPlannerTransaction>,
        expenseCategoryTags: Array<Tag>,
        customers: Readonly<Array<Customer>>,
        invoices: Array<CleanerPlannerInvoice>,
        generalExpenseCategory: ExpenseCategory,
        cutOff: string
    ) {
        const transactions: Array<Transaction> = [];
        const existingSqueegeeTransactions = Data.all<Transaction>('transactions');

        const jobs: Array<Job> = [];

        for (const customer of customers) {
            for (const job of Object.keys(customer.jobs).map(id => customer.jobs[id])) {
                jobs.push(job);
            }
        }

        for (const cpTransaction of cpTransactions) {
            let transaction: Transaction | undefined = existingSqueegeeTransactions.find(x => x._externalId === cpTransaction.Id);
            const job = cpTransaction.JobId && jobs.find(x => x._externalId === cpTransaction.JobId);
            const jobId = job ? job._id : undefined;
            const customerId = job ? job.customerId : undefined;
            switch (cpTransaction.TypeId) {
                case '0': {
                    // Invoice
                    const invoiceId = cpTransaction.InvoiceId;
                    const cpInvoice = (invoiceId && invoices.find(x => x.Id === invoiceId)) || undefined;
                    if (cpInvoice) {
                        if (!cpInvoice.IsQuote || cpInvoice.IsQuote.toLowerCase() === 'false') {
                            const invoice = this.getInvoice(cpInvoice, cpTransaction);
                            if (invoice) {
                                transaction = new Transaction(
                                    TransactionType.Invoice,
                                    'invoice.imported',
                                    Number(cpTransaction.Total || 0),
                                    customerId,
                                    jobId,
                                    `${cpTransaction.PaymentReference || ''} ${cpTransaction.Description || ''}`.trim(),
                                    this.cpDate(cpTransaction.Date),
                                    undefined,
                                    invoice
                                );
                            }
                        }
                    } else {
                        const invoice = this.getInvoiceFromCPTran(cpTransaction);

                        transaction = new Transaction(
                            TransactionType.Invoice,
                            'invoice.imported',
                            Number(cpTransaction.Total || 0),
                            customerId,
                            jobId,
                            `${cpTransaction.PaymentReference || ''} ${cpTransaction.Description || ''}`.trim(),
                            this.cpDate(cpTransaction.Date),
                            undefined,
                            invoice
                        );
                    }
                    break;
                }
                case '1': {
                    // Payment
                    transaction = new Transaction(
                        TransactionType.Payment,
                        'payment.imported',
                        -Number(cpTransaction.Total || 0),
                        customerId,
                        jobId,
                        `${cpTransaction.PaymentReference || ''} ${cpTransaction.Description || ''}`.trim(),
                        this.cpDate(cpTransaction.Date)
                    );
                    break;
                }
                case '2': {
                    // Expense
                    const category =
                        (cpTransaction.ExpenseCategoryId &&
                            expenseCategoryTags.find(x => x._externalId === cpTransaction.ExpenseCategoryId)) ||
                        generalExpenseCategory;
                    transaction = new Expense(
                        'expense.imported',
                        Number(cpTransaction.Total || 0),
                        `${cpTransaction.PaymentReference || ''} ${cpTransaction.Description || ''}`.trim(),
                        <ExpenseCategory>category,
                        undefined,
                        this.cpDate(cpTransaction.Date),
                        customerId,
                        jobId
                    );
                    break;
                }
                case '3': {
                    // Write off
                    transaction = new Transaction(
                        TransactionType.Adjustment,
                        'adjustment.write-off-imported',
                        -Number(cpTransaction.Total || 0),
                        customerId,
                        jobId,
                        `${cpTransaction.PaymentReference || ''} ${cpTransaction.Description || ''}`.trim(),
                        this.cpDate(cpTransaction.Date)
                    );
                    break;
                }
                default: {
                    Logger.error(`${cpTransaction.TypeId} not supported`, cpTransaction);
                }
            }

            if (transaction && transaction.amount) {
                transaction.status = 'complete';
                transaction._externalId = cpTransaction.Id;
                transaction.createdDate = this.cpDate(cpTransaction.Created);
                transaction.updatedDate = transaction.createdDate;
                transactions.push(transaction);
            }
        }

        const transactionsByCustomer = transactions.reduce<{ [id: string]: Array<Transaction> }>((map, next) => {
            if (next.customerId) {
                if (!map[next.customerId]) map[next.customerId] = [];
                map[next.customerId].push(next);
            }
            return map;
        }, {});

        const transactionsToCreate: Array<Transaction> = [];

        for (const id of Object.keys(transactionsByCustomer)) {
            let value = 0;
            const ts = transactionsByCustomer[id].sort(sortByCreatedDateAsc);

            for (const transaction of ts) {
                if (transaction.date < cutOff) {
                    value += -transaction.amount;
                } else {
                    transactionsToCreate.push(transaction);
                }
            }

            const adjustment = new Transaction(
                TransactionType.Adjustment,
                'adjustment.initial-balance-imported',
                -value,
                id,
                undefined,
                'Cleaner Planner initial balance',
                cutOff
            );
            adjustment.createdDate = cutOff;
            adjustment.updatedDate = cutOff;
            transactionsToCreate.push(adjustment);
        }

        return transactionsToCreate;
    }

    private getInvoice(cpInvoice: CleanerPlannerInvoice, cpTransaction: CleanerPlannerTransaction) {
        const taxEnabled = cpTransaction.Vat !== '0';
        const priceIncludesTax = cpTransaction.Vat === '0';
        const taxRate = Number(cpTransaction.Vat) || 0;

        const invoice = (cpInvoice && new Invoice(priceIncludesTax, taxRate, taxEnabled)) || undefined;
        if (cpInvoice && invoice) {
            invoice.date = this.cpDate(cpInvoice.Date || cpInvoice.Created);
            invoice.dueDate = this.cpDate(cpInvoice.Date || cpInvoice.Created);
            invoice.subtotal = Number(cpTransaction.Amount || 0);
            invoice.totalTax = Number(cpTransaction.Vat || 0);
            invoice.total = Number(cpTransaction.Total || 0);
            invoice.notes = `${cpTransaction.PaymentReference || ''} ${cpInvoice.TransactionNotes || ''} ${
                cpTransaction.Description || ''
            }`.trim();
            invoice.paid = true;
        }
        return invoice;
    }

    private getInvoiceFromCPTran(cpTransaction: CleanerPlannerTransaction) {
        const taxEnabled = cpTransaction.Vat !== '0';
        const priceIncludesTax = cpTransaction.Vat === '0';
        const taxRate = Number(cpTransaction.Vat) || 0;

        const invoice = new Invoice(priceIncludesTax, taxRate, taxEnabled);

        invoice.date = this.cpDate(cpTransaction.Date || cpTransaction.Created);
        invoice.dueDate = this.cpDate(cpTransaction.Date || cpTransaction.Created);
        invoice.subtotal = Number(cpTransaction.Amount || 0);
        invoice.totalTax = Number(cpTransaction.Vat || 0);
        invoice.total = Number(cpTransaction.Total || 0);
        invoice.notes = `${cpTransaction.PaymentReference || ''} ${cpTransaction.Description || ''}`.trim();
        invoice.paid = true;

        return invoice;
    }

    private async createSqueegeeCustomers(
        contacts: Array<CleanerPlannerContact>,
        customers: Array<CleanerPlannerCustomer>,
        jobs: Array<CleanerPlannerJob>,
        customerSources: Array<CleanerPlannerCustomerSource>,
        rounds: Array<JobGroup>,
        services: Array<Tag>,
        notes: Array<CleanerPlannerNote>,
        generalService: Service
    ) {
        const existingSqueegeeCustomers = Data.all<Customer>('customers');

        const squeegeeCustomersTransactionsAndJobOccurrences: Array<Customer | Transaction | JobOccurrence> = [];

        for (const cpCustomer of customers) {
            let customer = <Customer>existingSqueegeeCustomers.find(x => x._externalId === cpCustomer.Id);
            if (!customer) {
                let source = '';
                if (cpCustomer.SourceId) {
                    const sourceItem = customerSources.find(x => x.Id === cpCustomer.SourceId);
                    if (sourceItem) source = sourceItem.Name;
                }

                const contact = contacts.find(x => x.Id === cpCustomer.ContactId);
                const name = contact ? `${contact.Title} ${contact.FirstName} ${contact.LastName}`.trim() || 'Unknown' : 'Unknown';
                const location = new Location();

                const addressDescription = this.getAddressDescription(contact);

                location.addressDescription = addressDescription;

                const email = (contact && contact.Email) || undefined;
                let phone = (contact && contact.Phone) || undefined;
                let otherPhone = (contact && contact.Mobile) || undefined;
                if (otherPhone && !phone) {
                    phone = otherPhone;
                    otherPhone = undefined;
                }
                customer = CustomerService.create(name, location, phone, email, otherPhone);
                customer._externalId = cpCustomer.Id;
                customer.source = source;
                customer.createdDate = this.cpDate(cpCustomer.Created);
                customer.updatedDate = customer.createdDate;
            }

            if (cpCustomer.GoCardlessId && cpCustomer.GoCardlessPreAuthId) {
                customer.externalIds ??= {};
                customer.externalIds.gocardless = cpCustomer.GoCardlessId;
                customer.paymentProviderMetaData ??= {};
                customer.paymentProviderMetaData.gocardless = {
                    sourceOrMandateId: cpCustomer.GoCardlessPreAuthId,
                    status: 'active',
                };
            }

            const customerJobs = jobs.filter(x => x.CustomerId === cpCustomer.Id);
            const existingSqueegeeCustomerJobs = Object.keys(customer.jobs).map(x => customer.jobs[x]);

            let balance = 0;
            let active: boolean | undefined;

            for (const cpJob of customerJobs) {
                const job = existingSqueegeeCustomerJobs.find(x => x._externalId === cpJob.Id);
                const contact = contacts.find(x => x.Id === cpJob.ContactId);
                const addressDescription = this.getAddressDescription(contact, '');
                if (!job) {
                    balance += Number(cpJob.Balance || 0);
                    const name = contact ? `${contact.Title} ${contact.FirstName} ${contact.LastName}`.trim() || 'Unknown' : 'Unknown';
                    if (customer.name === 'Unknown' || !customer.name) customer.name = name;
                    const result = this.createJob(cpJob, customer, notes, rounds, services, generalService, addressDescription);

                    if (result) {
                        customer.jobs[result.job._id] = result.job;
                        active = result.isActive || !!active;

                        if (result.jobOccurrence) {
                            squeegeeCustomersTransactionsAndJobOccurrences.push(result.jobOccurrence);
                        }
                    }
                } else {
                    // Update address
                    let jobLocation = new Location();
                    if (addressDescription) {
                        jobLocation.addressDescription = addressDescription;
                    } else {
                        jobLocation = Utilities.copyObject(customer.address);
                    }

                    if (JSON.stringify(jobLocation) !== JSON.stringify(job.location)) {
                        job.location = jobLocation;
                    }
                }
            }
            if (active === undefined) active = customerJobs.length > 0;
            if (!active) customer.state = 'inactive';

            squeegeeCustomersTransactionsAndJobOccurrences.push(customer);

            if (balance !== 0) {
                squeegeeCustomersTransactionsAndJobOccurrences.push(
                    new Transaction(
                        TransactionType.Adjustment,
                        'adjustment.initial-balance-imported',
                        -balance,
                        customer._id,
                        undefined,
                        'Cleaner Planner current balance'
                    )
                );
            }
        }

        return squeegeeCustomersTransactionsAndJobOccurrences;
    }

    private getAddressDescription(contact: CleanerPlannerContact | undefined, defaultValue = 'Unknown') {
        const addressParts: Array<string> = [];
        if (contact && contact.Company && contact.Company.trim()) addressParts.push(contact.Company.trim());
        if (contact && contact.Address1 && contact.Address1.trim()) addressParts.push(contact.Address1.trim());
        if (contact && contact.Address2 && contact.Address2.trim()) addressParts.push(contact.Address2.trim());
        if (contact && contact.Town && contact.Town.trim()) addressParts.push(contact.Town.trim());
        if (contact && contact.County && contact.County.trim()) addressParts.push(contact.County.trim());
        if (contact && contact.Postcode && contact.Postcode.trim()) addressParts.push(contact.Postcode.trim());
        return addressParts.length ? addressParts.join(', ') : defaultValue;
    }

    private createJob(
        cpJob: CleanerPlannerJob,
        customer: Customer,
        notes: Array<CleanerPlannerNote>,
        rounds: Array<JobGroup>,
        services: Array<Tag>,
        generalService: Tag,
        addressDescription?: string
    ) {
        const nextDueDate = moment(this.cpDate(cpJob.Due || cpJob.NextDue || cpJob.WorksheetDefaultNextDue));
        const lastDoneDate = moment(this.cpDate(cpJob.LastDone));
        //if (nextDueDate.isValid()) {
        const callFirst = (cpJob.ReminderModeId && cpJob.ReminderModeId.trim() === '1') || false;
        const jobNotes =
            notes
                .filter(x => !!x.JobId && x.JobId === cpJob.Id)
                .map(x => (x.Created && x.Created.substring(0, 10) + '\n') + x.Text)
                .join('\n') + (callFirst ? '\n#!☎️ call first' : '');

        let jobLocation = new Location();
        if (addressDescription) {
            jobLocation.addressDescription = addressDescription;
        } else {
            jobLocation = Utilities.copyObject(customer.address);
        }

        let frequencyType: FrequencyType = FrequencyType.NoneRecurring;
        //let isQuote = false;
        let isActive = true;

        switch (cpJob.StatusId) {
            case '2': // Suspended
            case '3': // Cancelled
            case '4': // Complete
            case '5': // Transferred
                isActive = false;
                break;
            case '0': // Quote
                //isQuote = nextDueDate.isAfter(); // we dont want historic quotes
                isActive = false; // dont activate job for quote
                break;
            case '1': // Active
                if (cpJob.ScheduleEnabled === 'True') {
                    switch ((cpJob.ScheduleFrequencyId && cpJob.ScheduleFrequencyId.trim()) || '') {
                        case '1': // every x days
                            frequencyType = FrequencyType.Days;
                            break;
                        case '2': // every x weeks
                            frequencyType = FrequencyType.Weeks;
                            break;
                        case '3': // every x months
                            frequencyType = FrequencyType.DayOfMonth;
                            break;
                    }
                }
                break;
        }

        const round = rounds.find(x => x._externalId === cpJob.RoundId.toString());
        const service = services.find(x => x._externalId === cpJob.ServiceId.toString());

        let durationText = '00:30';
        const duration = Number(cpJob.Duration || 20);
        if (!isNaN(duration)) {
            durationText = `${Math.floor(duration / 60)}:${duration % 60}`;
        }

        const isoDueDate =
            (nextDueDate && nextDueDate.isValid() && nextDueDate.format('YYYY-MM-DD')) ||
            (lastDoneDate.isValid() && lastDoneDate.format('YYYY-MM-DD')) ||
            '';

        if (isoDueDate) {
            const job = new Job(
                customer._id,
                undefined,
                undefined,
                isoDueDate,
                jobLocation,
                undefined,
                jobNotes,
                Number(cpJob.TotalPrice || 0),
                undefined,
                Number(cpJob.ScheduleInterval || 0),
                frequencyType,
                round ? [round] : undefined,
                service ? [service] : [generalService],
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                durationText,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
            );
            job._externalId = cpJob.Id;
            job.plannedOrder = Number(cpJob.WorksheetIndex) || undefined;

            const result: { job: Job; jobOccurrence?: JobOccurrence; isActive: boolean } = { job, isActive };
            if (lastDoneDate.isValid()) {
                result.jobOccurrence = new JobOccurrence(job, lastDoneDate.format('YYYY-MM-DD'), JobOccurrenceStatus.Done);
                result.jobOccurrence.paymentStatus = 'unbillable';
                result.jobOccurrence.isoCompletedDate = lastDoneDate.format('YYYY-MM-DD');
            }
            return result;
        }
    }

    private cpDate(cpDate: string) {
        const date = moment(cpDate, 'DD/MM/YYYY HH:mm:ss');
        return date.format('YYYY-MM-DD');
    }

    private createTagEntity(entity: CleanerPlannerTagEntity, tagType: TagType, existingTags: Array<Tag>): Tag {
        let tag = existingTags.find(x => x._externalId === entity.Id);
        if (!tag) {
            tag = new Tag(entity.Name, tagType);
            tag.createdDate = this.cpDate(entity.Created);
            tag._externalId = entity.Id;
        }
        return tag;
    }

    private async showImportCompleteDialog() {
        new LoaderEvent(false);
        if (this.warnings.length) {
            const warningsHtml = ApplicationState.localise('import.complete-with-warnings') + `<br>${this.warnings.join('<br>')}`;
            new Prompt('prompts.complete-title', <any>warningsHtml, { cancelLabel: '' }).show();
        } else {
            new Prompt('prompts.complete-title', 'import.complete-no-warnings', { cancelLabel: '' }).show();
        }
    }

    private parseCSV(csv: string) {
        return parse(csv, { header: true, skipEmptyLines: true });
    }

    private async getData<TValidType extends CleanerPlannerCommonEntity>(filename: string, validType: TValidType) {
        let csvAsString: string;

        if (Array.isArray(this.files)) {
            const csv = this.files.find(x => x.name.toLowerCase() === filename.toLowerCase());
            if (!csv) throw `${filename} not found.`;
            csvAsString = await Utilities.readFileAsString(csv);
        } else {
            if (!this.files[filename]) throw `${filename} not found.`;
            csvAsString = this.files[filename];
        }

        const output = { warnings: <Array<string>>[], error: '', success: false, result: <Array<TValidType>>[] };
        try {
            const parsedCSV = this.parseCSV(csvAsString);
            if (parsedCSV && parsedCSV.data && parsedCSV.data.length) {
                const validator = ImportValidator.verifyCSVHeaders(Object.keys(parsedCSV.data[0]), Object.keys(validType));
                if (validator.isValid) {
                    output.result = parsedCSV.data.slice(0);
                    output.warnings = this.warnings.splice(0);
                    output.success = true;
                } else {
                    throw `${filename} is missing the following columns: ${validator.errors.join(', \n')}`;
                }
            }
        } catch (error) {
            Logger.error(`Error during getData from Cleaner Planner file ${filename}`, error);
            throw new Error(error);
        }

        return output.result;
    }
}
