import type { Customer, JobGroup, JobOccurrence, Service, SqueegeeRecord, StoredObject, Tag } from '@nexdynamic/squeegee-common';
import { JobOrderData, TagType, Transaction, TransactionType } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { SelectCustomerDialog } from '../Customers/Components/SelectCustomerDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { FileUploadDialog } from '../Dialogs/FileUploadDialog';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { ScheduleService } from '../Schedule/ScheduleService';
import { SelectTagsDialog } from '../Tags/Components/SelectTagsDialog';
import { TagService } from '../Tags/TagService';
import { Utilities } from '../Utilities';
import { Data } from './Data';
import { ImportValidator } from './ImportValidator';
import { SqueegeeParser } from './SqueegeeParser';
import { createBackup } from './createBackup';

export class SqueegeeImporter {
    private static requiredColumns: Array<keyof SqueegeeRecord> = ['customer name', 'customer address'];

    public static async importJobsOnlySqueegeeCSV(customer?: Customer) {
        if (!ApplicationState.hasUltimateOrAbove) {
            new NotifyUserMessage('account.ultimate');
            return;
        }

        if (!customer) {
            const dialog = new SelectCustomerDialog();
            const customer = await dialog.show(DialogAnimation.SLIDE_UP);
            if (!customer) throw new Error('No customer selected');
        }

        const fileDialog = new FileUploadDialog(['.csv', '.json'], 'uploads.csv-import-file');
        const files = await fileDialog.show();
        if (files && files.length) {
            const file = files[0];
            try {
                new LoaderEvent(true);

                const fileContent = await Utilities.readFileAsString(file);

                await SqueegeeImporter.import({
                    csv: fileContent,
                    filetype: file.name.endsWith('json') ? 'json' : 'csv',
                    deleteExistingData: false,
                    customer,
                });

                new LoaderEvent(false);
            } catch (error) {
                Logger.error('Error in importJobsOnlySqueegeeCSV() on SettingsDialog', { error });
            }
        }
    }

    public static async import({
        csv,
        filetype,
        deleteExistingData = false,
        customer,
    }: {
        csv: string;
        filetype: 'csv' | 'json';
        deleteExistingData?: boolean;
        customer?: Customer;
    }): Promise<{ error: string; success: boolean }> {
        const output = { error: '', success: false };
        try {
            const parsedSqueegeeRecords =
                filetype === 'csv' ? SqueegeeParser.parseCSV<SqueegeeRecord>(csv)?.data : (JSON.parse(csv) as Array<SqueegeeRecord>);
            if (parsedSqueegeeRecords?.length) {
                const columnHeadingsAreValidCheck = ImportValidator.verifyCSVHeaders(
                    Object.keys(parsedSqueegeeRecords[0]),
                    customer ? [] : SqueegeeImporter.requiredColumns
                );
                if (columnHeadingsAreValidCheck.isValid) {
                    await SqueegeeImporter.addRecords(parsedSqueegeeRecords, deleteExistingData, customer);
                    output.success = true;
                } else {
                    output.error =
                        'The Selected CSV/JSON is missing the following columns/fields: ' + columnHeadingsAreValidCheck.errors.join(', \n');
                }
            } else {
                output.error = 'Unable to parse the selected CSV/JSON file.';
            }
        } catch (error) {
            Logger.error(`Error during import Squeegee CSV/JSON file`, error);
            if (typeof error === 'string') output.error = error;
            else output.error = 'Unable to parse the selected CSV/JSON file.';
        }

        return output;
    }

    private static async addRecords(records: Array<SqueegeeRecord>, deleteExistingData: boolean, customer?: Customer): Promise<void> {
        const rounds: { [id: string]: JobGroup } = {};
        const services: { [id: string]: Tag } = {};

        if (!deleteExistingData) {
            await SqueegeeImporter.mergeWithExistingRounds(rounds);
            await SqueegeeImporter.mergeWithExistingServices(services);
        }

        new LoaderEvent(false);
        const defaultService = (
            await new SelectTagsDialog<Service>(
                [],
                TagType.SERVICE,
                1,
                1,
                undefined,
                undefined,
                undefined,
                undefined,
                'import.select-default-service'
            ).show()
        )?.[0];
        new LoaderEvent(true);

        if (!defaultService) throw ApplicationState.localise('import.no-default-service-selected');

        if (!services[defaultService.description]) services[defaultService.description] = defaultService;

        const customers: Array<Customer> = [];

        let jobOccurrences: Array<JobOccurrence> = [];
        const transactions: Array<Transaction> = [];
        const jobOrder = [] as Array<string>;

        for (let i = 0; i < records.length; i++) {
            const record = records[i];
            const customerAndJobOccurrences = SqueegeeParser.convertRecord(
                jobOrder,
                record,
                rounds,
                services,
                customers,
                defaultService,
                customer
            );

            if (!customerAndJobOccurrences || !customerAndJobOccurrences.customer) throw new Error('Could not convert customer record');

            if (customerAndJobOccurrences.jobOccurrences && customerAndJobOccurrences.jobOccurrences.length)
                jobOccurrences = jobOccurrences.concat(customerAndJobOccurrences.jobOccurrences);

            if (customerAndJobOccurrences.isNewCustomer) {
                const balance = SqueegeeImporter.getCustomerBalance(record, customerAndJobOccurrences.customer);
                if (balance) transactions.push(balance);
            }

            if (customers.indexOf(customerAndJobOccurrences.customer) === -1) {
                customers.push(customerAndJobOccurrences.customer);
            }
        }

        if (!customers.length) throw 'Could not import squeegee CSV';

        try {
            const validator = ImportValidator.validateOutput(customers, transactions, rounds);
            if (!validator.isValid) throw 'Squeegee CSV failed validation ' + '\n' + validator.errors.join('\n');
            // create backup with audit info before deleting or importing data
            const localisationParams = {
                customerCount: customers.length.toString(),
                jobCount: jobOccurrences.length.toString(),
                importType: 'squeegee-import',
                user: ApplicationState.dataEmail,
                deleteExistingData: deleteExistingData ? 'yes' : 'no',
            };
            const actionDetailsDescription = ApplicationState.localise('backup.reason.import.description', localisationParams);
            const backup = await createBackup({
                suppressErrorDialog: true,
                initiatorTask: 'import-data',
                auditInfo: { items: [...customers], actionDetailsDescription },
            });
            if (!backup) throw ApplicationState.localise('prompts.backup-error-text');

            const jobOrderData = ScheduleService.getJobOrderData() || new JobOrderData('global-day');

            if (deleteExistingData) await Data.deleteAllData(false);

            jobOrderData.data = jobOrder.concat(jobOrderData.data || []).filter((x, y, z) => z.lastIndexOf(x) === y);

            await SqueegeeImporter.addToDatabase(customers, transactions, rounds, services, jobOccurrences, jobOrderData);

            Data.initSync();
        } catch (error) {
            throw 'Could not import squeegee CSV ' + error;
        }
    }

    private static async addToDatabase(
        customers: Array<Customer>,
        transactions: Array<Transaction>,
        rounds: { [id: string]: Tag },
        services: { [id: string]: Tag },
        jobOccurrences: Array<JobOccurrence>,
        jobOrderData: JobOrderData
    ) {
        const roundsArray = Object.keys(rounds).map(key => rounds[key]);
        const servicesArray = Object.keys(services).map(key => services[key]);
        let all: Array<StoredObject> = [jobOrderData];
        all = all.concat(customers, transactions, roundsArray, servicesArray, jobOccurrences);
        await Data.put(all);
    }

    private static async mergeWithExistingRounds(rounds: { [id: string]: Tag }) {
        const existingRounds = TagService.getTagsByType(TagType.ROUND);

        if (existingRounds && existingRounds.length) {
            for (let i = 0; i < existingRounds.length; i++) {
                const existingRound = existingRounds[i];
                rounds[existingRound.description] = existingRound;
            }
        }

        return rounds;
    }

    private static async mergeWithExistingServices(services: { [id: string]: Tag }) {
        const existingServices = TagService.getTagsByType(TagType.SERVICE);

        if (existingServices && existingServices.length) {
            for (let i = 0; i < existingServices.length; i++) {
                const existingService = existingServices[i];
                services[existingService.description] = existingService;
            }
        }

        return services;
    }

    private static getCustomerBalance(record: SqueegeeRecord, customer: Customer): Transaction | undefined {
        const balance = Number((record['customer balance'] || '0').trim());

        if (!isNaN(balance) && balance !== 0)
            return new Transaction(
                TransactionType.Adjustment,
                'adjustment.initial-balance-imported',
                balance < 0 ? Math.abs(balance) : 0 - balance,
                customer._id,
                undefined,
                'Balance import'
            );
    }
}
