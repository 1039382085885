import type {
    Attachment,
    ConnectedServiceSettings,
    CustomerProvider,
    CustomerState,
    ICustomerPaymentProvidersData,
    IPaymentProviderMetadata,
    Job,
    JobOccurrence,
    Notification,
    Quote,
    QuotedJob,
    StoredObject,
    Transaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { Account, Alert, ConnectedServiceConfigData, Customer, Linker, getTaxConfig, uuid } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import type { ICustomerMatch } from '../ConnectedServices/Customers/ICustomerMatch';
import { Data } from '../Data/Data';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Select } from '../Dialogs/Select';
import { TextDialog } from '../Dialogs/TextDialog';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { SendNotificationService } from '../Notifications/SendNotificationService';
import type { ValidationErrorMessage } from '../Notifications/ValidationErrorMessage';
import { QuoteService } from '../Quotes/QuoteService';
import { Api } from '../Server/Api';
import { Utilities } from '../Utilities';
import { CustomerFormDialog } from './Components/CustomerFormDialog';
import { SelectCustomerDialog } from './Components/SelectCustomerDialog';
import { CustomerDeletedMessage, CustomerSavedMessage } from './CustomerMessage';

import moment from 'moment';
import { Prompt } from '../Dialogs/Prompt';
import { removeContactFromAudiences } from '../ReactUI/send/features/audiences/AudienceService';
import { CustomerDialog } from './Components/CustomerDialog';

type PaymentProvider = keyof ICustomerPaymentProvidersData;
export class CustomerService {
    public static create(...args: ConstructorParameters<typeof Customer>) {
        const customer = new Customer(...args);
        return CustomerService.setDefaults(customer);
    }
    private static setDefaults(customer: Customer): Customer {
        customer.defaultNotificationMethod = ApplicationState.account.defaultNotificationMethod;
        customer.takePaymentOnInvoiced = ApplicationState.account.defaultAutoPaymentCollection ? true : false;

        if (
            (ApplicationState.account.defaultAutoPaymentCollection as boolean) === true || // Legacy check for old data.
            ApplicationState.account.defaultAutoPaymentCollection === 'gocardless'
        ) {
            customer.automaticPaymentMethod = 'gocardless';
        } else if (ApplicationState.account.defaultAutoPaymentCollection === 'stripe') {
            customer.automaticPaymentMethod = 'stripe';
        } else customer.automaticPaymentMethod = undefined;

        return customer;
    }

    public static getCustomers() {
        return Data.all<Customer>('customers');
    }

    public static getCustomer(id: string) {
        return Data.get<Customer>(id);
    }

    public static async getDeactivateDeleteCustomerReasonIfRequired(
        action: 'Delete' | 'Deactivation'
    ): Promise<{ reason?: string; cancelled?: boolean }> {
        const requireReasons = ApplicationState.getSetting('global.require-deactivate-delete-customer-reasons', false);
        const reasons = ApplicationState.getSetting('global.deactivate-delete-customer-reasons', '');

        if (requireReasons === false) return {};

        const deactivateDeleteCustomerReasons = reasons
            .split('\n')
            .map(x => x.trim())
            .filter(x => !!x);

        new LoaderEvent(false);

        if (deactivateDeleteCustomerReasons.length) {
            const options = [{ text: 'Other', value: '' }].concat(
                deactivateDeleteCustomerReasons.map((text: TranslationKey) => ({ text, value: text }))
            );
            const deactivateDeleteCustomerReasonSelector = new Select('Select Reason' as TranslationKey, options, 'text', 'value');
            deactivateDeleteCustomerReasonSelector.disableLocalisation = true;

            const result = await deactivateDeleteCustomerReasonSelector.show();
            if (deactivateDeleteCustomerReasonSelector.cancelled) return { cancelled: true };

            if (result && result.value) return { reason: result.value };
        }

        const reasonOtherDialog = new TextDialog(
            `${action} Reason` as TranslationKey,
            `Enter the customer ${action.toLowerCase()} reason` as TranslationKey,
            '',
            '',
            x => (x.length < 3 ? ('You must enter at least 3 characters' as TranslationKey) : true)
        );

        const reason = await reasonOtherDialog.show();
        if (reasonOtherDialog.cancelled) return { cancelled: true };

        return { reason };
    }

    public static validateCustomerAndNotifyUI(customer: Customer): Array<ValidationErrorMessage> {
        const customerNameErrors = Utilities.validateAndNotifyUI(!!customer.name && !!customer.name.length, 'validation.name-required');
        const customerAddressErrors = Utilities.validateAndNotifyUI(
            !!customer.address && !!customer.address.addressDescription && !!customer.address.addressDescription.length,
            'validation.address-required'
        );
        return customerNameErrors.concat(customerAddressErrors);
    }

    public static validateCommaSeparatedEmailsAndNotifyUI(emails?: string) {
        const errors = CustomerService.validateCommaSeparatedEmails(emails);
        const customerEmailError = Utilities.validateAndNotifyUI(errors === true, Utilities.localiseList(errors === true ? [] : errors));
        return customerEmailError;
    }

    public static validateCommaSeparatedEmails(emails?: string): true | Array<TranslationKey> {
        if (!emails) return true;

        const errors = [] as Array<TranslationKey>;
        for (const email of emails.split(',')) {
            if (!Account.EMAIL_REGEX.test(email.trim())) {
                errors.push(ApplicationState.localise('invalid.email-error-message', { email }));
            }
        }

        return (errors.length && errors) || true;
    }

    public static async addOrUpdateCustomer(customer: Customer, ignoreValidation = false, showSaveMsg = true) {
        if (!ignoreValidation) {
            const errors = CustomerService.validateCustomerAndNotifyUI(customer);
            if (errors.length) throw errors;
        }
        customer.name = Utilities.toTitleCase(customer.name);
        await Data.put(customer);
        if (showSaveMsg) new CustomerSavedMessage(customer);
    }

    public static checkSaveNewEmailEmail = async (newEmail: string, customer: Customer, invoiceTransaction?: Transaction) => {
        if (!newEmail) return;

        if (invoiceTransaction?.invoice && invoiceTransaction.invoice.emailTo !== newEmail) {
            invoiceTransaction.invoice.emailTo = newEmail;
            await Data.put(invoiceTransaction);
        }

        if (customer.email !== newEmail) {
            const message = customer.email ? 'prompts.customer-email-replace' : 'prompts.customer-email-update';
            const localisationParams: { [key: string]: string } = customer.email
                ? {
                      currentemail: customer.email,
                      newemail: newEmail,
                  }
                : { email: newEmail };
            if (
                await new Prompt('prompts.confirm-title', message, {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.no',
                    localisationParams,
                }).show()
            ) {
                customer.email = newEmail;
                await CustomerService.addOrUpdateCustomer(customer);
            }
        }
    };

    public static async removeCustomer(customer: Customer) {
        try {
            const reason = await CustomerService.getDeactivateDeleteCustomerReasonIfRequired('Delete');
            if (reason.cancelled) throw 'You must provide a reason for deleting the customer';

            customer.deletedReason = reason.reason;
            customer.deletedDate = moment().format('YYYY-MM-DD');

            await removeContactFromAudiences(customer);

            await Data.delete(customer);
            new CustomerDeletedMessage(customer._id);
        } catch (error) {
            Logger.error(`Error during remove customer ${customer._id} in customer service`, error);
            throw error;
        }
    }

    public static async applyMatchedMandatesToCustomers(
        matchedItems: Array<ICustomerMatch>,
        customers: Readonly<Array<Customer>>,
        copyContactDetails: boolean,
        provider: CustomerProvider
    ) {
        const customersToUpdate = {} as { [customerId: string]: Customer };
        const connectedServiceSettings = ApplicationState.getSetting<ConnectedServiceSettings>('global.connected-services') || {};
        if (!connectedServiceSettings[provider]) connectedServiceSettings[provider] = new ConnectedServiceConfigData();

        const providerConfig = connectedServiceSettings[provider] as ConnectedServiceConfigData;

        if (!providerConfig.entityMap) providerConfig.entityMap = {};

        for (const match of matchedItems) {
            //If there is a mandate id and its not already the same save this customer with new mandate id
            CustomerService.applyMatchedCustomer(match, provider, customers, providerConfig, copyContactDetails, customersToUpdate);
        }

        const customersToSave = Object.keys(customersToUpdate).map(id => customersToUpdate[id]);
        if (customersToSave.length) await Data.put(customersToSave);
        await ApplicationState.setSetting('global.connected-services', connectedServiceSettings);
    }

    public static applyMatchedCustomer(
        match: ICustomerMatch,
        provider: CustomerProvider,
        customers: Readonly<Array<Customer>>,
        providerConfig: ConnectedServiceConfigData,
        copyContactDetails: boolean,
        updatedCustomers: { [customerId: string]: Customer }
    ) {
        const paymentProvider = provider as PaymentProvider;
        let updated = false;
        if (match.thirdPartyCustomer && match.squeegeeCustomer && match.status === 'confirmed') {
            //Check there if there is another customer with same ids if there is remove it

            // THERE CAN ONLY BE ONE, remove from other customers if it exists;
            const tpcExternalId = match.thirdPartyCustomer.externalIds?.[provider];
            if (tpcExternalId) {
                delete providerConfig.entityMap[tpcExternalId];
                delete providerConfig.entityMap[match.squeegeeCustomer._id];
                const removeExternalIds = customers.filter(
                    x =>
                        x.externalIds?.[provider] === match.thirdPartyCustomer?.externalIds?.[provider] &&
                        x._id !== match.squeegeeCustomer?._id
                );

                for (const customer of removeExternalIds) {
                    if (customer.externalIds?.[provider]) delete customer.externalIds[provider];
                    if (customer.paymentProviderMetaData?.[paymentProvider]) delete customer.paymentProviderMetaData[paymentProvider];
                    updatedCustomers[customer._id] = customer;
                }
            }

            CustomerService.setExternalIdForMatch(match, provider, updatedCustomers);

            const existingPmtProviderMetaData = match.squeegeeCustomer.paymentProviderMetaData?.[paymentProvider] as
                | IPaymentProviderMetadata
                | undefined;
            const newPmtProviderMetaData = match.thirdPartyCustomer?.paymentProviderMetaData?.[paymentProvider];

            // if we have new payment provider data
            if (newPmtProviderMetaData && newPmtProviderMetaData.sourceOrMandateId) {
                // No payment provider meta data on incoming connection
                if (!existingPmtProviderMetaData) {
                    if (!match.squeegeeCustomer.paymentProviderMetaData) match.squeegeeCustomer.paymentProviderMetaData = {};
                    match.squeegeeCustomer.paymentProviderMetaData[paymentProvider] = newPmtProviderMetaData;
                    updated = true;
                } else {
                    if (existingPmtProviderMetaData.sourceOrMandateId !== newPmtProviderMetaData.sourceOrMandateId) {
                        existingPmtProviderMetaData.sourceOrMandateId = newPmtProviderMetaData.sourceOrMandateId;
                        updated = true;
                    }

                    if (existingPmtProviderMetaData.status !== newPmtProviderMetaData.status) {
                        existingPmtProviderMetaData.status = newPmtProviderMetaData.status;
                        updated = true;
                    }

                    if (updated) {
                        updatedCustomers[match.squeegeeCustomer._id] = match.squeegeeCustomer;
                        return;
                    }
                }
            } else {
                if (existingPmtProviderMetaData) {
                    if (match.squeegeeCustomer.paymentProviderMetaData)
                        delete match.squeegeeCustomer.paymentProviderMetaData[paymentProvider];
                    updatedCustomers[match.squeegeeCustomer._id] = match.squeegeeCustomer;
                    return;
                } else return;
            }

            if (copyContactDetails) {
                const updatedDetails = CustomerService.addContactDetailsFromThirdPartyCustomer(
                    match.thirdPartyCustomer,
                    match.squeegeeCustomer
                );

                if (updatedDetails && updatedCustomers) updatedCustomers[match.squeegeeCustomer._id] = match.squeegeeCustomer;
            }
        } else if (match.thirdPartyCustomer && !match.squeegeeCustomer) {
            const unmatchedTPCustomer = match.thirdPartyCustomer;
            // If a third party customer has not be assigned a squeegee customer remove the mandate id from all customers that match
            const customersWithUnmatchedTPId = customers.filter(
                c =>
                    (unmatchedTPCustomer.externalIds?.[provider] &&
                        c.externalIds?.[provider] === unmatchedTPCustomer.externalIds?.[provider]) ||
                    (unmatchedTPCustomer.paymentProviderMetaData?.[paymentProvider]?.sourceOrMandateId &&
                        c.paymentProviderMetaData?.[paymentProvider]?.sourceOrMandateId ===
                            unmatchedTPCustomer.paymentProviderMetaData?.[paymentProvider]?.sourceOrMandateId)
            );

            for (const customer of customersWithUnmatchedTPId) {
                if (customer.externalIds?.[provider]) {
                    delete customer.externalIds[provider];
                }

                if (customer.paymentProviderMetaData?.[paymentProvider]) {
                    delete customer.paymentProviderMetaData[paymentProvider];
                }
                updatedCustomers[customer._id] = customer;
            }
        } else if (!match.thirdPartyCustomer && match.squeegeeCustomer) {
            // Unmatched customer so clear this providers meta data
            const unmatchedCustomer = match.squeegeeCustomer;
            const customer = customers.find(c => c._id === unmatchedCustomer._id);
            if (!customer) return;

            if (customer.externalIds?.[provider]) {
                delete customer.externalIds[provider];
                updated = true;
            }

            if (customer.paymentProviderMetaData?.[paymentProvider]) {
                delete customer.paymentProviderMetaData[paymentProvider];
                updated = true;
            }
            if (providerConfig.entityMap[match.squeegeeCustomer._id]) {
                delete providerConfig.entityMap[match.squeegeeCustomer._id];
            }
            if (updated) updatedCustomers[customer._id] = customer;
        }
    }

    private static setExternalIdForMatch(
        match: ICustomerMatch,
        provider: CustomerProvider,
        updatedCustomers: { [customerId: string]: Customer }
    ) {
        if (!match.squeegeeCustomer) return;
        if (!match.squeegeeCustomer.externalIds) match.squeegeeCustomer.externalIds = {};
        if (!match.thirdPartyCustomer?.externalIds?.[provider]) delete match.squeegeeCustomer.externalIds[provider];
        else {
            const externalId = match.thirdPartyCustomer.externalIds[provider];
            match.squeegeeCustomer.externalIds[provider] = externalId;
        }
        updatedCustomers[match.squeegeeCustomer._id] = match.squeegeeCustomer;
    }

    public static async cancelCustomerMandate(customer: Customer) {
        if (!customer.paymentProviderMetaData?.gocardless?.sourceOrMandateId) return;
        try {
            switch (customer.automaticPaymentMethod) {
                case 'gocardless':
                    await Api.delete(
                        Api.apiEndpoint,
                        `/api/payments/gocardless-mandate-cancel/${customer.paymentProviderMetaData.gocardless.sourceOrMandateId}/${customer._id}`
                    );
                    break;
            }
        } catch (error) {
            Logger.error(`Error during cancel customer mandate in customer service for customer ${customer._id}`, error);
        }

        customer.paymentProviderMetaData.gocardless.status = 'canceled';

        await Data.put(customer);
    }

    public static addContactDetailsFromThirdPartyCustomer(thirdPartyCustomer: Customer, customer: Customer): boolean {
        let updated = false;
        if (thirdPartyCustomer.telephoneNumber && !customer.telephoneNumber) {
            customer.telephoneNumber = thirdPartyCustomer.telephoneNumber;
            updated = true;
        }

        if (thirdPartyCustomer.email && !customer.email) {
            customer.email = thirdPartyCustomer.email;
            updated = true;
        }

        if (
            thirdPartyCustomer.name &&
            (!customer.name || !/[\w\d]/.test(customer.name) || customer.name.toLowerCase().trim() === 'unknown')
        ) {
            customer.name = thirdPartyCustomer.name;
            updated = true;
        }
        return updated;
    }

    private static copyCustomerFields(
        targetCustomer: Customer,
        sourceCustomer: Customer,
        mergeNotes: boolean,
        keepNotesOfOldDetails: boolean
    ) {
        const updatedRecords = [] as Array<StoredObject>;

        for (const jobId in sourceCustomer.jobs) {
            const originalJob = sourceCustomer.jobs[jobId];
            if (!originalJob) continue;
            const job = Utilities.copyObject(originalJob) as Job;
            job.customerId = targetCustomer._id;
            targetCustomer.jobs[jobId] = job;
        }

        if (!!sourceCustomer.telephoneNumber && (!targetCustomer.telephoneNumber || !targetCustomer.telephoneNumber.trim()))
            targetCustomer.telephoneNumber = sourceCustomer.telephoneNumber;

        if (!!sourceCustomer.telephoneNumberOther && (!targetCustomer.telephoneNumberOther || !targetCustomer.telephoneNumberOther.trim()))
            targetCustomer.telephoneNumberOther = sourceCustomer.telephoneNumberOther;

        if (!!sourceCustomer.source && (!targetCustomer.source || !targetCustomer.source.trim()))
            targetCustomer.source = sourceCustomer.source;

        if (!!sourceCustomer.email && (!targetCustomer.email || !targetCustomer.email.trim())) targetCustomer.email = sourceCustomer.email;

        if (
            !!sourceCustomer.address &&
            !!sourceCustomer.address.addressDescription &&
            !!sourceCustomer.address.addressDescription.trim() &&
            (!targetCustomer.address || !targetCustomer.address.addressDescription || !targetCustomer.address.addressDescription.trim())
        )
            targetCustomer.address = Utilities.copyObject(sourceCustomer.address);

        if (keepNotesOfOldDetails) {
            this.recordOldNotes(targetCustomer, sourceCustomer);
        }

        if (sourceCustomer.notes && mergeNotes) {
            targetCustomer.notes += '\nMerged Notes: ' + sourceCustomer.notes;
        }

        return updatedRecords;
    }

    private static recordOldNotes(destCustomer: Customer, sourceCustomer: Customer) {
        const currentNotes = destCustomer.notes ? `\n\n${destCustomer.notes}` : '';
        destCustomer.notes = `** MERGE NOTES **\nMerged Customer: ${sourceCustomer.name ? sourceCustomer.name : 'NO NAME'}
        ${sourceCustomer.telephoneNumber ? '\nTelephone: ' + sourceCustomer.telephoneNumber : ''}
        ${sourceCustomer.address ? '\nAddress: ' + sourceCustomer.address.addressDescription : ''}
        ${sourceCustomer.source ? '\nSource: ' + sourceCustomer.source : ''}
        ${sourceCustomer.telephoneNumberOther ? '\nOther Telephone: ' + sourceCustomer.telephoneNumberOther : ''}
        ${sourceCustomer.email ? '\nEmail: ' + sourceCustomer.email : ''}\n
        ${currentNotes}`;
    }

    private static updateOccurrenceCustomerIds(customerId: string, destCustomerId: string) {
        const occurrences = Data.all<JobOccurrence>('joboccurrences', { customerId });

        for (const occurrence of occurrences) {
            occurrence.customerId = destCustomerId;
        }

        return occurrences;
    }

    private static updateNotificationCustomerIds(customerId: string, destCustomerId: string) {
        // WTF: don't miss out legacy notifications that didn't have a customerId
        const notificationsByAddressee = Data.all<Notification>('notifications', { addressee: customerId });
        for (const notification of notificationsByAddressee) {
            notification.addressee = destCustomerId;
        }

        const notificationsByCustomerId = Data.all<Notification>('notifications', { customerId });
        for (const notification of notificationsByCustomerId) {
            notification.customerId = destCustomerId;
        }

        return notificationsByCustomerId.concat(notificationsByAddressee);
    }

    private static async updateAttachmentIds(customerId: string, destCustomerId: string) {
        // linker for customerId we are transferring from
        const oldLinker = Data.get<Linker>(Linker.getId(customerId, 'attachment'));
        if (!oldLinker) return [];

        // existing linker for customer transferring to
        let existingLinker = Data.get<Linker>(Linker.getId(destCustomerId, 'attachment'));
        if (!existingLinker) {
            existingLinker = Linker.clone(oldLinker, destCustomerId);
            await Data.put(existingLinker);
        } else {
            // link each link on customer we are transferring from
            const oldLinks = Linker.getLinks(oldLinker);
            for (const link of oldLinks) {
                const object = Data.get<Attachment>(link.targetId);
                if (!object) continue;
                Linker.link(existingLinker, object);
            }
        }

        return [oldLinker, existingLinker];
    }

    private static updateQuoteAndQuoteJobIds(customerId: string, destCustomerId: string) {
        const quotedJobs = Data.all<QuotedJob>('quotedjobs', { customerId });
        const quotes = Data.all<Quote>('quotes', { customerId });
        const allQuoteObjects = [...quotedJobs, ...quotes];
        for (const job of allQuoteObjects) {
            job.customerId = destCustomerId;
        }

        return allQuoteObjects;
    }

    private static updateTransactionCustomerIds(customerId: string, destCustomerId: string) {
        const transactions = Data.all<Transaction>('transactions', { customerId });

        for (const transaction of transactions) {
            transaction.customerId = destCustomerId;
            if (!transaction.invoice) continue;
            transaction.invoice.customerId = destCustomerId;
        }

        return transactions;
    }

    public static async mergeCustomers(primary: Customer, secondary: Customer, mergeNotes: boolean, keepNotesOfOldDetails: boolean) {
        try {
            const dataToUpdate: StoredObject[] = [];

            if (!primary) return;
            if (!secondary) return;

            // duplicate primary as new object
            const targetCustomer = Utilities.copyObject<Customer>(primary);
            targetCustomer._id = uuid();
            for (const jobId in targetCustomer.jobs) targetCustomer.jobs[jobId].customerId = targetCustomer._id;

            // required merges
            dataToUpdate.push(...this.updateOccurrenceCustomerIds(secondary._id, targetCustomer._id));
            dataToUpdate.push(...this.updateTransactionCustomerIds(secondary._id, targetCustomer._id));
            dataToUpdate.push(...(await this.updateAttachmentIds(secondary._id, targetCustomer._id)));
            dataToUpdate.push(...this.updateQuoteAndQuoteJobIds(secondary._id, targetCustomer._id));
            dataToUpdate.push(...this.updateNotificationCustomerIds(secondary._id, targetCustomer._id));

            // bring across information on primary customer
            dataToUpdate.push(...this.copyCustomerFields(targetCustomer, secondary, mergeNotes, keepNotesOfOldDetails));
            dataToUpdate.push(...this.updateQuoteAndQuoteJobIds(primary._id, targetCustomer._id));
            dataToUpdate.push(...(await this.updateAttachmentIds(primary._id, targetCustomer._id)));
            dataToUpdate.push(...this.updateTransactionCustomerIds(primary._id, targetCustomer._id));
            dataToUpdate.push(...this.updateNotificationCustomerIds(primary._id, targetCustomer._id));
            dataToUpdate.push(...this.updateOccurrenceCustomerIds(primary._id, targetCustomer._id));

            dataToUpdate.push(targetCustomer);

            // update objects changed thus far
            await Data.put(dataToUpdate);

            // delete and push customers
            dataToUpdate.push(secondary);
            dataToUpdate.push(primary);

            primary._deleted = true;
            primary.notes += ` (Merged into ${targetCustomer._id})`;

            secondary._deleted = true;
            secondary.notes += ` (Merged into ${targetCustomer._id})`;

            Data.put([primary, secondary]);

            return targetCustomer;
        } catch (ex) {
            Logger.error('Error merging customers', ex);
        }
    }

    public static async sendMessage(
        customer: Customer,
        fields: { content: string; description: string; senderName: string; subject?: string }
    ): Promise<void> {
        if (!ApplicationState.isVerifiedForMessaging) {
            new NotifyUserMessage('notification.account-email-not-verified-message-sending');
            return;
        }

        if (!customer.defaultNotificationMethod) throw 'Message failed, you have not set a default notification for this customer.';
        const methods = Customer.getDefaultNotificationMethods(customer);

        if (!methods.length)
            throw new Error('Customer has none of the default notification methods: ' + customer.defaultNotificationMethod);

        const queue: Array<Promise<Notification | undefined>> = [];

        for (const method of methods) {
            const notification = SendNotificationService.send(
                fields.description,
                method.to,
                customer._id,
                method.type,
                fields.senderName,
                fields.content,
                ApplicationState.account.bccEmailNotifications,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                method.type === 'Email',
                undefined,
                undefined,
                fields.subject
            );
            if (notification) queue.push(notification);
        }

        await Promise.all(queue);
    }

    public static getQuotes(customerId: string) {
        return Data.all<Quote>('quotes', { customerId }).slice(0).sort(QuoteService.sortByQuoteNumber);
    }

    public static async createCustomerCanvasedAlert(customer: Customer) {
        try {
            if (ApplicationState.isInAnyRole('Canvasser')) {
                const title = ApplicationState.localise('customer.new-customer-canvassed');
                const description = ApplicationState.localise('customer.new-customer-canvassed-description', {
                    customerName: customer.name,
                    customerAddress: customer.address.addressDescription || '',
                    userName: ApplicationState.userAuthorisation.userName,
                    userEmail: ApplicationState.userAuthorisation.userEmail,
                });
                const alert = new Alert(title, description, 'customer', [{ id: customer._id, icon: 'person', name: customer.name }]);

                await Data.put(alert);
            }
        } catch (error) {
            Logger.error('Unable to create canvased customer alert', error);
        }
    }

    public static getTaxConfig(customer: Customer) {
        return getTaxConfig(customer, ApplicationState.account);
    }

    public static async updateState(customer: Customer, state: CustomerState) {
        customer.state = state;
        await Data.put(customer);
    }

    public static async selectOrCreateCustomer(
        refCustomer: {
            ref: string;
            name: string;
            address?: string;
            email?: string;
            telephoneNumber?: string;
        },
        customer?: Customer
    ) {
        const selectCustomerDialog = new SelectCustomerDialog(customer, undefined, undefined, refCustomer, true);

        let selectedCustomer = await selectCustomerDialog.show(DialogAnimation.SLIDE_UP);
        if (selectCustomerDialog.cancelled || !selectedCustomer) return;

        if (selectCustomerDialog.customerIsNew) {
            const customerDialog = new CustomerFormDialog(selectedCustomer);
            selectedCustomer = await customerDialog.show(DialogAnimation.SLIDE_UP);
            if (customerDialog.cancelled) return;
        }
        return selectedCustomer;
    }

    public static restoreDeletedCustomers = async (searchText?: string, auto?: boolean): Promise<void> => {
        if (!auto || !searchText) {
            const searchDialog = new TextDialog(
                'deleted-customer.search-title',
                'deleted-customer.search-label',
                searchText || '',
                '',
                value => (value?.length >= 3 ? true : 'deleted-customer.search-minimum-length'),
                false,
                'text',
                'general.search'
            );

            searchText = await searchDialog.show();
            if (searchDialog.cancelled) return;
        }

        new LoaderEvent(true);

        const deletedCustomers = await Api.get<Array<Customer>>(null, `/api/deleted-customers?q=${searchText}`);

        new LoaderEvent(false);

        if (!deletedCustomers?.data?.length) {
            await new Prompt('deleted-customer.no-results-title', 'deleted-customer.no-results-message', { cancelLabel: '' }).show();
            if (!auto) return this.restoreDeletedCustomers(searchText);
            return;
        }

        if (auto && deletedCustomers.data.length === 1) {
            const customerDialog = new CustomerDialog(deletedCustomers.data[0]);
            await customerDialog.show();
            return;
        }

        const options = deletedCustomers.data.map(customer => ({
            id: customer._id,
            text: `${customer.name || 'Unknown'} - ${customer.address?.addressDescription || 'Unknown'}`,
            customer,
        }));

        const deletedCustomersSelect = new Select('deleted-customer.select-title', options, 'text', 'id');
        const selected = await deletedCustomersSelect.show();

        if (deletedCustomersSelect.cancelled) {
            if (!auto) return this.restoreDeletedCustomers(searchText);
            return;
        }

        const selectedCustomer = selected.customer;

        const customerDialog = new CustomerDialog(selectedCustomer);
        await customerDialog.show();

        if (customerDialog.cancelled) return this.restoreDeletedCustomers(searchText);
    };
}
