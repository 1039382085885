import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { LoaderEvent } from '../Events/LoaderEvent';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';

export class VerifyEmail {
    @bindable email: string;
    emailVerified = false;

    attached() {
        this.email = ApplicationState.account.email;
        this.emailVerified = !RethinkDbAuthClient.session?.unverified;
    }

    protected async sendConfirmEmail() {
        await RethinkDbAuthClient.resendConfirmationEmail(this.email);
        new NotifyUserMessage('notifications.sign-up-confirm-email-sent', { email: this.email });
    }

    public async checkEmailVerificationStatus() {
        new LoaderEvent(true, true, 'loader.checking-email-verification-status');
        await RethinkDbAuthClient.validateSession();
        this.emailVerified = !RethinkDbAuthClient.session?.unverified;
        setTimeout(() => new LoaderEvent(false), 1000);
    }

    protected async support() {
        await ApplicationState.getSupport('Squeegee App Feedback', false, '', 'support.support-request');
    }
}
