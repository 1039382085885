import type { Setting } from '@nexdynamic/squeegee-common';
import { SqueegeeLocalStorageChangeEvent } from '../Events/SqueegeeLocalStorageChangeEvent';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import type { DataStoreManager } from './DataStoreManager';
import { DexieDataStoreManager } from './DexieDataStoreManager';
import { SQLiteDataStoreManager } from './SQLiteDataStoreManager';

const packageJson = require('../../package.json');

export class SqueegeeLocalStorage {
    private static _items = {} as { [keyName: string]: string | null };
    private static _localStorageManager: DataStoreManager;
    public static isFreshInstall = true;

    private static initialisePromise: Promise<void> | undefined;
    public static async initialise() {
        if (SqueegeeLocalStorage.initialisePromise) return SqueegeeLocalStorage.initialisePromise;
        SqueegeeLocalStorage.initialisePromise = new Promise(async (resolve, reject) => {
            try {
                Logger.info('Initialising SqueegeeLocalStorage');

                if (!GlobalFlags.isAppleMobileApp) {
                    SqueegeeLocalStorage._localStorageManager = new DexieDataStoreManager('localStorageWrapper', [
                        '&_id, resourceType, createdDate, updatedDate, timestamp',
                        '&_id',
                    ]);
                } else {
                    SqueegeeLocalStorage._localStorageManager = new SQLiteDataStoreManager('localStorageWrapper');
                }

                await SqueegeeLocalStorage._localStorageManager.initialise();

                for (const setting of SqueegeeLocalStorage._localStorageManager.getCollectionDataArr() as Array<Setting<string>>) {
                    if (!setting) continue;
                    this._items[setting._id] = !setting.value && setting.value !== '' ? null : setting.value;
                    SqueegeeLocalStorage.isFreshInstall = false;
                }

                SqueegeeLocalStorage.initialisePromise = undefined;
                return resolve();
            } catch (error) {
                Logger.error('Failed to initialise SqueegeeLocalStorage', error);
                reject(error);
            }
        });

        return SqueegeeLocalStorage.initialisePromise;
    }
    public static async setItem(keyName: string, value: string) {
        try {
            SqueegeeLocalStorage._items[keyName] = value;
            await SqueegeeLocalStorage._localStorageManager.writeAllToDataStore([{ _id: keyName, value } as Setting<string>]);
            new SqueegeeLocalStorageChangeEvent({ [keyName]: value });
            return true;
        } catch (error) {
            return false;
        }
    }

    public static getItem(keyName: string) {
        try {
            return SqueegeeLocalStorage._items[keyName] === undefined ? null : SqueegeeLocalStorage._items[keyName];
        } catch (error) {
            if (error && error.name && error.name === 'SecurityError')
                alert(
                    'Unable to access local storage due to security restrictions, please check your browser configuration is set to allow cookies and local storage for this application.'
                );
            return null;
        }
    }

    public static async clear() {
        try {
            const deviceKey = SqueegeeLocalStorage.getItem('deviceKey');
            const apiEndpointOverride = SqueegeeLocalStorage.getItem('apiEndpointOverride');
            const devToolsKey = 'devModeEnabled_' + (packageJson?.version || '');
            const devMode = SqueegeeLocalStorage.getItem(devToolsKey);
            const lastReleaseNotesShown = SqueegeeLocalStorage.getItem('lastReleaseNotesShown');
            const accountsCacheKey = `${RethinkDbAuthClient.session?.email}-accessible-accounts`;
            const accountsCache = SqueegeeLocalStorage.getItem(accountsCacheKey);

            const changes = Object.keys(SqueegeeLocalStorage._items).reduce<Record<string, null>>((changes, key) => {
                changes[key] = null;
                return changes;
            }, {});

            new SqueegeeLocalStorageChangeEvent(changes);

            SqueegeeLocalStorage._items = {};

            await this._localStorageManager.removeAllDataStoresFromDisk();
            await this.initialise();

            if (deviceKey) await SqueegeeLocalStorage.setItem('deviceKey', deviceKey);
            if (apiEndpointOverride) await SqueegeeLocalStorage.setItem('apiEndpointOverride', apiEndpointOverride);
            if (devMode) await SqueegeeLocalStorage.setItem(devToolsKey, devMode);
            if (lastReleaseNotesShown) await SqueegeeLocalStorage.setItem('lastReleaseNotesShown', lastReleaseNotesShown);
            if (accountsCache) await SqueegeeLocalStorage.setItem(accountsCacheKey, accountsCache);

            return true;
        } catch (error) {
            return false;
        }
    }

    public static async removeItem(keyName: string) {
        try {
            delete SqueegeeLocalStorage._items[keyName];

            await SqueegeeLocalStorage._localStorageManager.deleteAllFromDataStore([keyName]);

            return true;
        } catch (error) {
            return false;
        }
    }
}
