import type { Customer, JobGroup } from '@nexdynamic/squeegee-common';
import { Tag, TagType, Transaction, TransactionType } from '@nexdynamic/squeegee-common';
import { Logger } from '../Logger';
import { TagService } from '../Tags/TagService';
import { AworkaParser } from './AworkaParser';
import type { AworkaRecord } from './AworkaRecord';
import { Data } from './Data';
import { ImportValidator } from './ImportValidator';

export class AworkaImporter {
    private static requiredColoumns: Array<keyof AworkaRecord> = [
        'Customer Name',
        'Customer Address',
        'Customer Ref',
        'Day',
        'Job Address',
        'Price',
        'Frequency',
        'Status',
        'Next Due',
        'Round',
    ];

    public static async import(
        csv: string,
        deleteExistingData = false
    ): Promise<{ warnings: Array<string>; error: string; success: boolean }> {
        const output = { warnings: <Array<string>>[], error: '', success: false };
        try {
            const parsedCSV = AworkaParser.parseCSV(csv);
            if (parsedCSV && parsedCSV.data && parsedCSV.data.length) {
                const validator = ImportValidator.verifyCSVHeaders(Object.keys(parsedCSV.data[0]), AworkaImporter.requiredColoumns);
                if (validator.isValid) {
                    await AworkaImporter.addRecords(parsedCSV.data, deleteExistingData);
                    output.warnings = AworkaParser.warnings.splice(0);
                    output.success = true;
                } else {
                    output.error = 'The Selected CSV is missing the following columns: ' + validator.errors.join(', \n');
                }
            } else {
                output.error = 'Unable to parse the selected CSV.';
            }
        } catch (error) {
            Logger.error(`Error during import an Aworka CSV`, error);
            throw new Error(error);
        }
        return output;
    }

    private static async addRecords(records: Array<AworkaRecord>, deleteExistingData: boolean) {
        const rounds: { [id: string]: JobGroup } = {};
        const services: { [id: string]: Tag } = {};

        if (!deleteExistingData) {
            AworkaImporter.mergeWithExistingRounds(rounds);
            AworkaImporter.mergeWithExistingServices(services);
        } else {
            const defaultService = new Tag('Default Service', TagType.SERVICE);
            services[defaultService.description] = defaultService;
        }

        const customers: Array<Customer> = [];
        let transactions: Array<Transaction> = [];

        for (let i = 0; i < records.length; i++) {
            const record = records[i];
            try {
                const customer = AworkaParser.convertRecord(record, rounds, services, customers);

                if (customer) {
                    const newTransactions = AworkaImporter.getCustomerBalance(record, customer);
                    if (newTransactions.length) transactions = transactions.concat(newTransactions);
                }
            } catch (error) {
                throw new Error(error);
            }
        }
        if (customers.length) {
            try {
                const validator = ImportValidator.validateOutput(customers, transactions, rounds, services);
                if (validator.isValid) {
                    if (deleteExistingData) await Data.deleteAllData(false);
                    await AworkaImporter.addToDatabase(customers, transactions, rounds, services);
                    Data.initSync();
                } else {
                    throw new Error('Aworka Data failed validation ' + '\n' + validator.errors.join('\n'));
                }
            } catch (error) {
                throw new Error('Could not import aworka CSV' + error);
            }
        } else {
            throw new Error('Could not import aworka CSV');
        }
    }
    private static async addToDatabase(
        customers: Array<Customer>,
        transactions: Array<Transaction>,
        rounds: { [id: string]: Tag },
        services: { [id: string]: Tag }
    ) {
        const roundsArray = Object.keys(rounds).map(key => rounds[key]);
        const servicesArray = Object.keys(services).map(key => services[key]);
        let all: Array<any> = [];
        all = all.concat(customers, transactions, roundsArray, servicesArray);
        await Data.put(all);
    }

    private static mergeWithExistingRounds(rounds: { [id: string]: Tag }) {
        const existingRounds = TagService.getTagsByType(TagType.ROUND);

        if (existingRounds && existingRounds.length) {
            for (let i = 0; i < existingRounds.length; i++) {
                const existingRound = existingRounds[i];
                rounds[existingRound._id] = existingRound;
            }
        }

        return rounds;
    }

    private static getCustomerBalance(record: AworkaRecord, customer: Customer): Array<Transaction> {
        const balance = -(AworkaParser.getValue(record, ['Balance']) || 0);
        if (balance !== 0)
            return [
                new Transaction(
                    TransactionType.Adjustment,
                    'adjustment.initial-balance-imported',
                    balance,
                    customer._id,
                    undefined,
                    'Balance import'
                ),
            ];
        return [];
    }

    private static async mergeWithExistingServices(services: { [id: string]: Tag }) {
        const existingService = TagService.getTagsByType(TagType.SERVICE);

        if (existingService && existingService.length) {
            for (let i = 0; i < existingService.length; i++) {
                const existingRound = existingService[i];
                services[existingRound.description] = existingRound;
            }
        }

        if (!services['Default Service']) {
            const defaultService = new Tag('Default Service', TagType.SERVICE);
            services[defaultService.description] = defaultService;
        }

        return services;
    }
}
