import type {
    AccountUser,
    Customer,
    Job,
    JobGroup,
    Notification,
    Transaction,
    TranslationKey,
    UserRole,
} from '@nexdynamic/squeegee-common';
import { Alert, FrequencyType, JobOccurrence, JobOccurrenceStatus, sortByStringProperty } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import type { DataChangeNotificationType } from '../Data/DataChangeNotificationType';
import { prompt } from '../Dialogs/ReactDialogProvider';
import { Select } from '../Dialogs/Select';
import { TextDialog } from '../Dialogs/TextDialog';
import { LoaderEvent } from '../Events/LoaderEvent';
import { InvoiceService } from '../Invoices/InvoiceService';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { ScheduleActionEvent } from '../Schedule/Actions/ScheduleActionEvent';
import { ScheduleService } from '../Schedule/ScheduleService';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { getReasonText } from '../Settings/getSkipReasonText';
import { AssignmentService } from '../Users/Assignees/AssignmentService';
import { UserAuthorisationService } from '../Users/UserAuthorisationService';
import { UserService } from '../Users/UserService';
import { Utilities } from '../Utilities';
import { DateFilterOption } from './Filters/DateFilterOption';
import type { IIndicator } from './IIndicator';
import { JobService } from './JobService';
import { migrateLinkers } from './migrateLinkers';
import { migrateStoredEvents } from './migrateStoredEvents';

export class JobOccurrenceService {
    static resolveOccurrenceStatus(possibleOccurrence: JobOccurrence, today: string, invoiceTransaction: Transaction) {
        // Correct any inconsistencies on the occurrence.
        let updated = false;
        const paid = !!invoiceTransaction.invoice && !!invoiceTransaction.invoice.paid;
        const paymentStatus = paid ? 'paid' : 'invoiced';

        const existingOccurrence = Data.get<JobOccurrence>(possibleOccurrence._id);
        const existingOccurrenceDate =
            existingOccurrence &&
            (existingOccurrence.isoCompletedDate || existingOccurrence.isoPlannedDate || existingOccurrence.isoDueDate);
        const possibleOccurrenceDate =
            possibleOccurrence.isoCompletedDate || possibleOccurrence.isoPlannedDate || possibleOccurrence.isoDueDate;

        if (
            ((existingOccurrenceDate && existingOccurrenceDate.substring(0, 10) <= today) ||
                possibleOccurrenceDate.substring(0, 10) <= today) &&
            (possibleOccurrence.status === JobOccurrenceStatus.NotDone || possibleOccurrence.status === JobOccurrenceStatus.Skipped)
        ) {
            Logger.info('🛠️ status' + possibleOccurrence.status + ':' + JobOccurrenceStatus.Done);
            possibleOccurrence.isoCompletedDate = possibleOccurrence.isoPlannedDate || possibleOccurrence.isoDueDate;
            if (existingOccurrenceDate && existingOccurrenceDate !== possibleOccurrenceDate) {
                possibleOccurrence.isoCompletedDate = existingOccurrenceDate;
                if (possibleOccurrence.isoPlannedDate && possibleOccurrence.isoPlannedDate !== existingOccurrenceDate)
                    possibleOccurrence.isoPlannedDate = existingOccurrenceDate;
            }
            updated = true;
        } else if (possibleOccurrenceDate.substring(0, 10) > today && possibleOccurrence.status === JobOccurrenceStatus.Done) {
            Logger.info('🛠️ status' + possibleOccurrence.status + ':' + JobOccurrenceStatus.NotDone);
            possibleOccurrence.status = JobOccurrenceStatus.NotDone;
            updated = true;
        }

        if (possibleOccurrence.paymentStatus !== paymentStatus) {
            Logger.info('🛠️ paymentStatus' + possibleOccurrence.paymentStatus + ':' + paymentStatus);
            possibleOccurrence.paymentStatus = paymentStatus;
            updated = true;
        }

        if (possibleOccurrence.invoiceTransactionId !== invoiceTransaction._id) {
            Logger.info('🛠️ invoiceTransactionId' + possibleOccurrence.invoiceTransactionId + ':' + invoiceTransaction._id);
            possibleOccurrence.invoiceTransactionId = invoiceTransaction._id;
            updated = true;
        }

        const newInvoiceDate =
            (invoiceTransaction.invoice && invoiceTransaction.invoice.date) || invoiceTransaction.date || invoiceTransaction.createdDate;

        if (possibleOccurrence.invoicedDate !== newInvoiceDate) {
            Logger.info('🛠️ invoicedDate' + possibleOccurrence.invoicedDate + ':' + newInvoiceDate);
            possibleOccurrence.invoicedDate = newInvoiceDate;
            updated = true;
        }

        if (paid && !possibleOccurrence.paidDate) {
            Logger.info('🛠️ paid AND paidDate' + possibleOccurrence.paidDate + ':' + today);
            possibleOccurrence.paidDate = today;
            updated = true;
        } else if (!paid && possibleOccurrence.paidDate) {
            Logger.info('🛠️ not paid AND paidDate' + possibleOccurrence.paidDate);
            possibleOccurrence.paidDate = undefined;
            updated = true;
        }

        if (
            !invoiceTransaction.voidedId &&
            invoiceTransaction.invoice &&
            invoiceTransaction.invoice.items &&
            invoiceTransaction.invoice.items.length
        ) {
            let price: undefined | number;
            for (const item of invoiceTransaction.invoice.items) {
                if (item && item.refID === possibleOccurrence._id && item.unitPrice) {
                    const quantity = item.quantity || 1;
                    price = (price || 0) + quantity * (item.unitPrice || 0);
                }
            }

            if (price !== undefined && price !== possibleOccurrence.price) {
                Logger.info('🛠️ correcting the job price with the invoice price ' + price);
                possibleOccurrence.price = price;
                updated = true;
            }
        }

        if (updated) return true;
    }

    public static async deleteOccurrencesBetweenDates(from: moment.Moment, to: moment.Moment) {
        const fromString = from.format();
        const toString = to.format();

        const occurrences = Data.all<JobOccurrence>(
            'joboccurrences',
            o =>
                o.status === JobOccurrenceStatus.NotDone &&
                (o.isoPlannedDate || o.isoDueDate) >= fromString &&
                (o.isoPlannedDate || o.isoDueDate) < toString
        );

        await Data.delete(occurrences.slice());
    }

    public static async deleteConcreteOverdueJobOccurrences(job: Job, beforeDate: string) {
        const toDelete = this.filterOutJobOccurrencesBeforeDate(
            Data.all<JobOccurrence>('joboccurrences', { jobId: job._id, status: JobOccurrenceStatus.NotDone }).slice(),
            beforeDate
        );
        await Data.delete(toDelete, true);
    }

    public static filterOutJobOccurrencesBeforeDate(occurrences: JobOccurrence[], beforeDate: string) {
        return occurrences.filter(e => {
            const date = e.isoCompletedDate || e.isoPlannedDate || e.isoDueDate;
            return date < beforeDate;
        });
    }

    private static _today = moment(moment().format('YYYY-MM-DD'));

    public static getCustomerJobOccurrence(customerId: string) {
        return Data.all<JobOccurrence>('joboccurrences', { customerId });
    }

    public static getOccurrencesByInvoiceId(invoiceId: string) {
        return Data.all<JobOccurrence>('joboccurrences', occurrence => occurrence.invoiceTransactionId === invoiceId);
    }

    public static getOccurrence(occurrenceId: string) {
        return Data.get<JobOccurrence>(occurrenceId);
    }

    public static async deleteExistingOccurrences(job: Job) {
        const occurrences = await JobOccurrenceService.getOccurrencesByJobId(job._id);
        if (occurrences.length) {
            const occurrencesToDelete = occurrences.filter(
                occurrence =>
                    occurrence.status === JobOccurrenceStatus.NotDone ||
                    (occurrence.status === JobOccurrenceStatus.Skipped &&
                        occurrence.isoCompletedDate &&
                        moment(occurrence.isoCompletedDate).isAfter(moment().subtract(1, 'day'), 'day'))
            );
            await Data.delete(occurrencesToDelete, true);
        }
    }

    public static async updateExistingOccurrencesOnJobUpdate(
        oldJob: Job,
        updatedJob: Job,
        ignoreExistingValue = false,
        fieldsToUpdate?: Array<keyof JobOccurrence>,
        assignees?: Array<string>
    ) {
        for (const occurrence of (await this.getOccurrencesByJobId(oldJob._id)).filter(x => x.status === JobOccurrenceStatus.NotDone)) {
            await JobOccurrenceService.updateOccurrenceFromJob(
                occurrence,
                oldJob,
                updatedJob,
                ignoreExistingValue,
                fieldsToUpdate,
                assignees
            );
        }
    }

    public static async updateOccurrenceFromJob(
        occurrence: JobOccurrence,
        oldJob: Job,
        updatedJob: Job,
        ignoreExistingValue = false,
        fieldsToUpdate?: Array<keyof JobOccurrence>,
        assignees?: Array<string>
    ) {
        let updated = false;
        if (!fieldsToUpdate || fieldsToUpdate.indexOf('price') > -1) {
            updated =
                JobOccurrenceService.updateOccurrenceFieldFromJob('price', ignoreExistingValue, occurrence, oldJob, updatedJob) || updated;
        }
        if (!fieldsToUpdate || fieldsToUpdate.indexOf('location') > -1) {
            updated = JobOccurrenceService.updateOccurrenceLocationFromJob(occurrence, oldJob, updatedJob, ignoreExistingValue) || updated;
        }
        if (!fieldsToUpdate || fieldsToUpdate.indexOf('duration') > -1) {
            updated =
                JobOccurrenceService.updateOccurrenceFieldFromJob('duration', ignoreExistingValue, occurrence, oldJob, updatedJob) ||
                updated;
        }
        if (!fieldsToUpdate || fieldsToUpdate.indexOf('rounds') > -1) {
            updated = JobOccurrenceService.updateOccurrenceRoundFromJob(occurrence, oldJob, updatedJob, ignoreExistingValue) || updated;
        }
        if (!fieldsToUpdate || fieldsToUpdate.indexOf('services') > -1) {
            updated = JobOccurrenceService.updateOccurrenceServicesFromJob(occurrence, oldJob, updatedJob, ignoreExistingValue) || updated;
        }
        if (!fieldsToUpdate || fieldsToUpdate.indexOf('description') > -1) {
            updated =
                JobOccurrenceService.updateOccurrenceFieldFromJob('description', ignoreExistingValue, occurrence, oldJob, updatedJob) ||
                updated;
        }
        if (!fieldsToUpdate || fieldsToUpdate.indexOf('assignedTo') > -1) {
            if (assignees) {
                if (!assignees.length) {
                    await AssignmentService.unassign(occurrence);
                } else {
                    await AssignmentService.assign(occurrence, assignees, false, true, true);
                }
            }
        }
        if (updated) await Data.put(occurrence, true, 'lazy');
    }

    private static updateOccurrenceServicesFromJob(occurrence: JobOccurrence, oldJob: Job, updatedJob: Job, ignoreExistingValue: boolean) {
        let _test = false;
        if (
            occurrence.services &&
            oldJob.services &&
            updatedJob.services &&
            (ignoreExistingValue ||
                occurrence.services
                    .map(x => x.description)
                    .sort()
                    .join('') ===
                    oldJob.services
                        .map(x => x.description)
                        .sort()
                        .join('')) &&
            occurrence.services
                .map(x => x.description)
                .sort()
                .join('') !==
                updatedJob.services
                    .map(x => x.description)
                    .sort()
                    .join('')
        ) {
            occurrence.services = updatedJob.services.slice();
            _test = true;
        }
        return _test;
    }

    private static updateOccurrenceRoundFromJob(occurrence: JobOccurrence, oldJob: Job, updatedJob: Job, ignoreExistingValue: boolean) {
        if (
            occurrence.rounds &&
            oldJob.rounds &&
            updatedJob.rounds &&
            (ignoreExistingValue ||
                occurrence.rounds
                    .map(x => x.description)
                    .sort()
                    .join('') ===
                    oldJob.rounds
                        .map(x => x.description)
                        .sort()
                        .join('')) &&
            occurrence.rounds
                .map(x => x.description)
                .sort()
                .join('') !==
                updatedJob.rounds
                    .map(x => x.description)
                    .sort()
                    .join('')
        ) {
            occurrence.rounds = updatedJob.rounds.slice();
            return true;
        }
        return false;
    }

    private static updateOccurrenceFieldFromJob(
        field: 'duration' | 'description' | 'price',
        ignoreExistingValue: boolean,
        occurrence: JobOccurrence,
        oldJob: Job,
        updatedJob: Job
    ) {
        if (
            typeof field === 'string' &&
            (ignoreExistingValue || occurrence[field] === oldJob[field]) &&
            occurrence[field] !== updatedJob[field]
        ) {
            (<any>occurrence)[field] = updatedJob[field];
            return true;
        }
        return false;
    }

    private static updateOccurrenceLocationFromJob(occurrence: JobOccurrence, oldJob: Job, updatedJob: Job, ignoreExistingValue: boolean) {
        if (
            occurrence.location &&
            oldJob.location &&
            updatedJob.location &&
            (ignoreExistingValue || occurrence.location.addressDescription === oldJob.location.addressDescription) &&
            occurrence.location.addressDescription !== updatedJob.location.addressDescription
        ) {
            occurrence.location = Utilities.copyObject(updatedJob.location);
            return true;
        }
        return false;
    }

    public static async resetOccurrenceToJobDefaults(occurrence: JobOccurrence, job: Job) {
        let updated = false;
        if (job.location && occurrence.location && job.location.addressDescription !== occurrence.location.addressDescription) {
            occurrence.location = Utilities.copyObject(job.location);
            updated = true;
        }

        if (occurrence.price !== job.price) {
            occurrence.price = job.price || 0;
            updated = true;
        }

        if (occurrence.duration !== job.duration) {
            occurrence.duration = job.duration;
            updated = true;
        }

        if (
            occurrence.rounds &&
            job.rounds &&
            occurrence.rounds
                .map(x => x.description)
                .sort()
                .join('') !==
                job.rounds
                    .map(x => x.description)
                    .sort()
                    .join('')
        ) {
            occurrence.rounds = job.rounds;
            updated = true;
        }

        if (
            occurrence.services &&
            job.services &&
            occurrence.services
                .map(x => x.description)
                .sort()
                .join('') !==
                job.services
                    .map(x => x.description)
                    .sort()
                    .join('')
        ) {
            occurrence.services = job.services;
            updated = true;
        }

        if (occurrence.description !== job.description) {
            occurrence.description = job.description;
            updated = true;
        }

        if (updated) await JobOccurrenceService.addOrUpdateJobOccurrence(occurrence);
        return updated;
    }

    public static getUninvoicedJobOccurrences(customer: Customer, excludeZeroPricedJobs: boolean) {
        const occurrences = [] as Array<JobOccurrence>;

        for (const jobId in customer.jobs) {
            const done = Data.all<JobOccurrence>('joboccurrences', { jobId, status: JobOccurrenceStatus.Done })
                .slice()
                .sort((a, b) => sortByStringProperty(a, b, 'isoDueDate', false));

            for (const occurrence of done) {
                if (
                    occurrence.paymentStatus === 'unbillable' ||
                    occurrence.invoiceTransactionId ||
                    (excludeZeroPricedJobs && !occurrence.price)
                )
                    continue;
                console.log('occurrence', occurrence);
                occurrences.push(occurrence);
            }
        }
        return occurrences;
    }

    public static countUninvoicedJobOccurrences(customer: Customer) {
        let count = 0;

        for (const jobId in customer.jobs) {
            count += Data.all<JobOccurrence>(
                'joboccurrences',
                x => !x.invoiceTransactionId && !!x.price && x.paymentStatus !== 'unbillable',
                {
                    jobId,
                    status: JobOccurrenceStatus.Done,
                }
            ).length;
        }
        return count;
    }

    public static getAllExistingOccurrences() {
        return Data.all<JobOccurrence>('joboccurrences').reduce<{ [jobId: string]: Array<JobOccurrence> }>((dictionary, occurrence) => {
            if (!dictionary[occurrence.jobId]) dictionary[occurrence.jobId] = [];

            dictionary[occurrence.jobId].push(occurrence);
            return dictionary;
        }, {});
    }

    public static getAllExistingOccurrencesForJob(jobId: string) {
        const occurrences = Data.all<JobOccurrence>('joboccurrences', undefined, { jobId });
        return { [jobId]: occurrences };
    }

    public static getStatusDictionary(statuses: Array<JobOccurrenceStatus>) {
        const dictionary: { [key: number]: true } = {};
        for (let i = 0; i < statuses.length; i++) {
            const status = statuses[i];
            dictionary[status] = true;
        }
        return dictionary;
    }

    public static validateJobAndNotifyUI(jobOccurrence: JobOccurrence) {
        const errors = Utilities.validateAndNotifyUI(
            !!jobOccurrence.location && !!jobOccurrence.location.addressDescription,
            'validation.job-location-required'
        );
        Utilities.validateAndNotifyUI(!!jobOccurrence.services && !!jobOccurrence.services.length, 'validation.service-required', errors);
        return errors;
    }

    public static async addOrUpdateJobOccurrence(jobOccurrence: JobOccurrence) {
        const errors = this.validateJobAndNotifyUI(jobOccurrence);
        if (errors.length) throw errors;

        await Data.put(jobOccurrence);
    }

    public static async revertJobOccurrence(occurrence: JobOccurrence, suppressScheduleActionEvent = false) {
        try {
            if (occurrence.invoiceTransactionId) {
                const invoice = await Data.get<Transaction>(occurrence.invoiceTransactionId);

                if (invoice) {
                    if (
                        (invoice.status === 'awaitingApiHandling' || invoice.status === 'apiHandlingInProgress') &&
                        !Api.getQueueItem(invoice._id)
                    ) {
                        throw 'Cannot revert this job as the invoice is still being processed.';
                    }
                    if (!invoice.voidedId) {
                        const distinctJobIds = invoice.invoice?.items
                            ?.map(x => x.refID)
                            .filter((ref, index, self) => self.indexOf(ref) === index)
                            .filter(x => x !== 'skip-charge');
                        const invoiceHasOtherJobsOnIt = (distinctJobIds?.length || 0) > 1;
                        if (!invoiceHasOtherJobsOnIt) {
                            await InvoiceService.cancelOrVoidInvoice(occurrence.invoiceTransactionId, suppressScheduleActionEvent);
                        } else {
                            throw 'Cannot mark this job as not done as the invoice has multiple jobs on it.';
                        }
                    }
                }
            }

            occurrence.status = JobOccurrenceStatus.NotDone;
            occurrence.isoCompletedDate = null;
            occurrence.invoiceTransactionId = undefined;
            occurrence.paidDate = undefined;
            occurrence.invoicedDate = undefined;
            occurrence.paymentStatus = undefined;
            occurrence.paymentTransactionId = undefined;
            occurrence.skipReason = undefined;
            occurrence.skippedBy = undefined;

            const notify: DataChangeNotificationType = suppressScheduleActionEvent ? 'lazy' : 'immediate';
            await Data.put(occurrence, true, notify);

            if (!suppressScheduleActionEvent) new ScheduleActionEvent('action-not-done', occurrence);

            return true;
        } catch (error) {
            if (!suppressScheduleActionEvent) {
                new NotifyUserMessage(error);
            }
            return error;
        }
    }

    public static async skipJobOccurrence(
        occurrence: JobOccurrence,
        suppressScheduleActionEvent = false,
        skipReason?: { reason?: string }
    ) {
        if (!skipReason) {
            const skipReasonCheck = await JobOccurrenceService.getSkipReasonIfRequired();
            if (skipReasonCheck.cancelled) {
                new NotifyUserMessage('notification.skip-reason-required');
                return;
            }

            skipReason = skipReasonCheck;
        }
        occurrence.skipReason = skipReason.reason;
        occurrence.status = JobOccurrenceStatus.Skipped;
        occurrence.isoCompletedDate = occurrence.isoPlannedDate || occurrence.isoDueDate;
        occurrence.skippedBy = RethinkDbAuthClient.session?.email
            ? UserService.getUser(RethinkDbAuthClient.session.email)?.name || RethinkDbAuthClient.session?.email
            : 'Unknown';

        const notify: DataChangeNotificationType = suppressScheduleActionEvent ? 'lazy' : 'immediate';

        if (occurrence.reminderSent) {
            const potentialNotification = Data.firstOrDefault<Notification>('notifications', { jobOccurrenceReminderId: occurrence._id });

            const isScheduledNotification =
                potentialNotification?.sendAtSecondsTimestamp && potentialNotification.sendAtSecondsTimestamp > Date.now() / 1000;

            if (isScheduledNotification) {
                potentialNotification.status = 'cancelled';
                await Data.put(potentialNotification);
            }
        }

        await Data.put(occurrence, true, notify);

        if (occurrence.customerId) {
            const customer = Data.get<Customer>(occurrence.customerId);
            if (customer) {
                await InvoiceService.autoCreateInvoiceForOccurrence(customer, occurrence);
            }
        }

        if (['Admin', 'Owner', 'Creator'].every(r => !ApplicationState.isInAnyRole(r as UserRole))) {
            const customer = occurrence.customerId && Data.get<Customer>(occurrence.customerId);
            if (!customer) return;

            const accountUser = UserService.getUser();
            const user = accountUser ? accountUser.name : 'A user';
            Data.put(Alert.createSkippedJobAlert(customer, occurrence, user));
        }

        if (!suppressScheduleActionEvent) new ScheduleActionEvent('action-skip', occurrence);
    }
    public static async getSkipReasonIfRequired(): Promise<{ reason?: string; cancelled?: boolean }> {
        if (!ApplicationState.account.requireSkipReason) return {};

        const skipReasons = ApplicationState.skipReasons;

        new LoaderEvent(false);

        if (skipReasons.length) {
            const options = [{ text: 'Other', value: '' }].concat(skipReasons.map(r => ({ text: getReasonText(r), value: r.name })));
            const skipReasonSelector = new Select('Select Reason' as TranslationKey, options, 'text', 'value');
            skipReasonSelector.disableLocalisation = true;

            const result = await skipReasonSelector.show();
            if (skipReasonSelector.cancelled) return { cancelled: true };

            if (result && result.value) return { reason: result.value };
        }

        const reasonOtherDialog = new TextDialog(
            'Skip Reason' as TranslationKey,
            'Enter a reason for skipping' as TranslationKey,
            '',
            '',
            x => (x.length < 3 ? ('You must enter at least 3 characters' as TranslationKey) : true)
        );

        const reason = await reasonOtherDialog.show();
        if (reasonOtherDialog.cancelled) return { cancelled: true };

        return { reason };
    }

    public static async getTimerPauseReasonIfRequired(): Promise<{ reason?: string; cancelled?: boolean }> {
        const requireReason = ApplicationState.getSetting('global.jobs.require-timer-pause-reason', false);
        if (!requireReason) return {};

        const timerPauseReasons = ApplicationState.getSetting('global.jobs.predefined-timer-pause-reasons', '')
            .split('\n')
            .map(x => x.trim())
            .filter(x => !!x);

        new LoaderEvent(false);

        if (timerPauseReasons.length) {
            const options = [{ text: 'Other', value: '' }].concat(timerPauseReasons.map((text: TranslationKey) => ({ text, value: text })));
            const timerPauseReasonSelector = new Select('Select Reason' as TranslationKey, options, 'text', 'value');
            timerPauseReasonSelector.disableLocalisation = true;

            const result = await timerPauseReasonSelector.show();
            if (timerPauseReasonSelector.cancelled) return { cancelled: true };

            if (result && result.value) return { reason: result.value };
        }

        const reasonOtherDialog = new TextDialog(
            'Other Reason' as TranslationKey,
            'Enter a reason for pausing the timer' as TranslationKey,
            '',
            '',
            x => (x.length < 3 ? ('You must enter at least 3 characters' as TranslationKey) : true)
        );

        const reason = await reasonOtherDialog.show();
        if (reasonOtherDialog.cancelled) return { cancelled: true };

        return { reason };
    }

    public static async checkForDuplicateOccurrences(occurrence: JobOccurrence, isoPlannedDate: string) {
        try {
            if (!occurrence.customerId) return;

            const dateFilter = new DateFilterOption(isoPlannedDate, isoPlannedDate);
            const statuses = [JobOccurrenceStatus.Done, JobOccurrenceStatus.NotDone];
            const jobSchedule =
                (await ScheduleService.getJobScheduleByCustomerIdAndJobId(
                    occurrence.customerId,
                    occurrence.jobId,
                    moment(isoPlannedDate),
                    dateFilter
                )) || [];
            if (jobSchedule && jobSchedule.length) {
                const duplicates = jobSchedule
                    .filter(x => x.date === isoPlannedDate && statuses.indexOf(x.occurrence.status) > -1)
                    .map(x => x.occurrence);
                if (duplicates.length) return duplicates;
            }
        } catch (error) {
            Logger.error('Error during replan job occurrence to day', { error, occurrence });
            return;
        }
    }

    public static async replanOccurrence(
        occurrence: JobOccurrence,
        isoPlannedDate: string,
        suppressScheduleActionEvent: boolean,
        replanJobSchedule: boolean,
        skipDuplicateCheck = false,
        unskipSkippedOccurrences = true
    ): Promise<JobOccurrence | undefined> {
        try {
            if (!occurrence.customerId) throw 'No customer ID specified on the job';

            if (occurrence.status === JobOccurrenceStatus.Done) throw 'Cannot replan a done occurrence';

            if (!skipDuplicateCheck && (await JobOccurrenceService.checkForDuplicateOccurrences(occurrence, isoPlannedDate)))
                throw 'Cannot replan occurrence to this date due to duplicate';
            let replanAppointment = false;

            const originalOccurrencePlannedDate = occurrence.isoPlannedDate || occurrence.isoDueDate;
            const originalId = occurrence._id;

            if (moment(isoPlannedDate).isSame(moment(originalOccurrencePlannedDate, 'day'))) throw 'Cannot replan to same date';

            const customer = Data.get<Customer>(occurrence.customerId);
            if (!customer) throw 'No customer to replan job';

            if (!customer.jobs[occurrence.jobId]) throw 'No job spec';

            const job = customer.jobs[occurrence.jobId];
            if (!job) return occurrence;

            if (job.frequencyType === FrequencyType.NoneRecurring) {
                // WTF: If the one we are moving is the main template one, update that too.
                if (job.date === (occurrence.isoPlannedDate || occurrence.isoDueDate)) {
                    job.date = isoPlannedDate;

                    const notify: DataChangeNotificationType = suppressScheduleActionEvent ? 'lazy' : 'immediate';
                    await Data.put(customer, true, notify);
                }
                replanJobSchedule = false;
                replanAppointment = true;
            } else {
                const roundId = job.rounds && job.rounds[0] && job.rounds[0]._id;
                const jobGroup = roundId && Data.get<JobGroup>(roundId);
                if (jobGroup && jobGroup.frequencyData) {
                    replanAppointment = true;
                    replanJobSchedule = false;
                } else {
                    replanAppointment = true;
                }
            }

            if (replanJobSchedule) {
                const updatedOccurrence = await JobOccurrenceService.replanJobSchedule(
                    occurrence,
                    job,
                    isoPlannedDate,
                    originalOccurrencePlannedDate,
                    suppressScheduleActionEvent
                );
                if (!updatedOccurrence) return;

                if (updatedOccurrence !== occurrence) occurrence = updatedOccurrence;
            } else if (replanAppointment) {
                if (occurrence.isoCompletedDate) occurrence.isoCompletedDate = null;
                if (occurrence.isoDueDate === isoPlannedDate) delete occurrence.isoPlannedDate;
                else occurrence.isoPlannedDate = isoPlannedDate;

                occurrence.reminderSent = false;

                if (unskipSkippedOccurrences && occurrence.status === JobOccurrenceStatus.Skipped) {
                    occurrence.status = JobOccurrenceStatus.NotDone;
                    delete occurrence.skipReason;
                }

                if (occurrence.status === JobOccurrenceStatus.NotScheduled) {
                    occurrence.status = JobOccurrenceStatus.NotDone;
                }

                const notify: DataChangeNotificationType = suppressScheduleActionEvent ? 'lazy' : 'immediate';
                await Data.put(occurrence, true, notify);
            }

            const unassignOnReplan = await ApplicationState.getSetting<boolean>('global.unassign-on-replan', false);
            if (unassignOnReplan) {
                await AssignmentService.unassign(occurrence, undefined, false, suppressScheduleActionEvent);
            }

            await migrateLinkers(originalId, occurrence._id);
            await migrateStoredEvents(originalId, occurrence._id);

            if (!suppressScheduleActionEvent) new ScheduleActionEvent('action-replan', occurrence);

            return occurrence;
        } catch (error) {
            if (!suppressScheduleActionEvent) new NotifyUserMessage(<TranslationKey>`Replan to ${isoPlannedDate} failed, ${error}`);
            Logger.error('Error in replanOccurrence on JobOccurrenceService', {
                occurrence,
                isoPlannedDate,
                suppressScheduleActionEvent,
                replanJobSchedule,
                skipDuplicateCheck,
                error,
            });
        }
    }

    public static async replanJobSchedule(
        originalOccurrence: JobOccurrence,
        job: Job,
        isoPlannedDate: string,
        originalOccurrencePlannedDate: string,
        suppressScheduleActionEvent: boolean
    ) {
        const occurrenceToUpdateIdOn = Utilities.copyObject(originalOccurrence);

        const duplicates = await JobOccurrenceService.checkForDuplicateOccurrences(originalOccurrence, isoPlannedDate);
        const someDuplicatesHaveBeenDoneSkippedOrReplanned = duplicates?.some(j => j.status !== JobOccurrenceStatus.NotDone);
        if (someDuplicatesHaveBeenDoneSkippedOrReplanned) {
            await prompt('prompts.job-already-scheduled-title', 'prompts.job-already-scheduled-text', {
                cancelLabel: '',
                localisationParams: { address: originalOccurrence.location?.addressDescription || '' },
            }).show();
            return;
        }

        const notify: DataChangeNotificationType = suppressScheduleActionEvent ? 'lazy' : 'immediate';
        await Data.delete(originalOccurrence, true, notify);

        await JobService.updateJobSchedule(
            job,
            isoPlannedDate,
            originalOccurrencePlannedDate,
            true,
            suppressScheduleActionEvent,
            originalOccurrence.isoDueDate
        );
        const oldId = occurrenceToUpdateIdOn._id;

        if (occurrenceToUpdateIdOn.isoCompletedDate) {
            occurrenceToUpdateIdOn.isoCompletedDate = null;
        }
        occurrenceToUpdateIdOn.isoDueDate = isoPlannedDate;
        occurrenceToUpdateIdOn.lastId = occurrenceToUpdateIdOn._id; //WTF? To keep the id for conflict checking
        occurrenceToUpdateIdOn._id = occurrenceToUpdateIdOn.jobId + isoPlannedDate;
        delete occurrenceToUpdateIdOn.isoPlannedDate;

        occurrenceToUpdateIdOn.reminderSent = false;

        await migrateLinkers(oldId, occurrenceToUpdateIdOn._id);
        await migrateStoredEvents(oldId, occurrenceToUpdateIdOn._id);

        if (occurrenceToUpdateIdOn.status === JobOccurrenceStatus.Skipped) {
            occurrenceToUpdateIdOn.status = JobOccurrenceStatus.NotDone;
            delete occurrenceToUpdateIdOn.skipReason;
        } else if (occurrenceToUpdateIdOn.status === JobOccurrenceStatus.NotScheduled) {
            occurrenceToUpdateIdOn.status = JobOccurrenceStatus.NotDone;
        }

        await Data.put(occurrenceToUpdateIdOn, true, notify);

        return occurrenceToUpdateIdOn;
    }

    public static getOccurrenceIndicators(occurrence: JobOccurrence, today: moment.Moment = this._today, distance?: number) {
        const indicators: Array<IIndicator> = [];
        let invoiceNumber = '';
        if (occurrence.invoiceTransactionId) {
            const transaction = Data.get<Transaction>(occurrence.invoiceTransactionId);
            if (transaction && transaction.invoice) {
                invoiceNumber = ` ${ApplicationState.account.invoiceSettings.invoicePrefix || ''}${
                    transaction.invoice.invoiceNumber || 'pending'
                }`;
            }
        }
        const assignees = AssignmentService.getAssignees(occurrence);

        for (const assignee of assignees) {
            let assigneeLabel = assignee.name;
            if (assignee.resourceType === 'accountuser') {
                const user = assignee as AccountUser;
                if (user.email !== ApplicationState.dataEmail) {
                    const auth = UserAuthorisationService.getUserAuthorisation(user.email);
                    if (!auth || auth.deactivated) assigneeLabel = assignee.name + ' (deactivated)';
                }
            }
            indicators.push({
                description: assigneeLabel,
                state: assignee.resourceType === 'accountuser' ? 'assignee-worker' : 'assignee-team',
            });
        }
        if (occurrence.status === JobOccurrenceStatus.NotScheduled) {
            indicators.push({ description: 'Unscheduled', state: 'overdue' });
        } else if (occurrence.status === JobOccurrenceStatus.Skipped) {
            indicators.push({ description: 'SKIPPED ', state: 'skipped' });
        } else if (occurrence.status === JobOccurrenceStatus.Done && occurrence.isoCompletedDate) {
            let description = 'DONE';
            let state = 'done';
            let stateDate: moment.Moment | undefined;
            switch (occurrence.paymentStatus) {
                case 'invoiced':
                    description = `Invoiced ${invoiceNumber}`;
                    if (occurrence.invoicedDate) stateDate = moment(occurrence.invoicedDate);
                    state = 'invoiced';
                    break;
                case 'paid':
                    description = `Invoice ${invoiceNumber} paid`;
                    if (occurrence.paidDate) stateDate = moment(occurrence.paidDate);
                    state = 'paid';
                    break;
                default:
                    stateDate = moment(occurrence.isoCompletedDate);
                    break;
            }

            if (stateDate) description = description + ' ' + (stateDate.isSame(today, 'day') ? 'Today' : stateDate.from(today));
            indicators.push({ description, state: state });
        } else {
            const dueDate = moment(occurrence.isoPlannedDate || occurrence.isoDueDate);
            const timeDescription: string = occurrence.time ? ' at ' + occurrence.time : '';

            if (dueDate.isBefore(today, 'day')) indicators.push({ description: dueDate.from(today) + timeDescription, state: 'overdue' });
            else if (dueDate.isSame(today, 'day')) indicators.push({ description: 'Today' + timeDescription, state: 'current' });
            else indicators.push({ description: dueDate.from(today) + timeDescription, state: 'default' });

            if (occurrence.time) indicators.push({ description: 'due at ' + occurrence.time, state: 'current' });

            if (occurrence.paymentStatus && occurrence.paymentStatus === 'invoiced' && occurrence.invoicedDate) {
                const stateDate = moment(occurrence.invoicedDate);
                let description = `Invoiced ${invoiceNumber}`;
                if (stateDate) description = description + ' ' + (stateDate.isSame(today, 'day') ? 'Today' : stateDate.from(today));
                indicators.push({
                    description,
                    state: 'invoiced',
                });
            } else if (occurrence.paymentStatus && occurrence.paymentStatus === 'paid' && occurrence.invoicedDate) {
                const stateDate = moment(occurrence.invoicedDate);
                let description = `Invoice ${invoiceNumber} paid`;
                if (stateDate) description = description + ' ' + (stateDate.isSame(today, 'day') ? 'Today' : stateDate.from(today));
                indicators.push({
                    description,
                    state: 'paid',
                });
            }

            if (occurrence.reminderSent) {
                const reminder = Data.firstOrDefault<Notification>('notifications', { jobOccurrenceReminderId: occurrence._id });

                if (reminder?.sendAtSecondsTimestamp && moment(reminder.sendAtSecondsTimestamp * 1000).isAfter(moment())) {
                    indicators.push({
                        description: 'Reminder Scheduled',
                        state: 'default',
                    });
                } else {
                    indicators.push({
                        description: 'Reminded',
                        state: 'default',
                    });
                }
            }
            if (distance) {
                indicators.push({
                    description: distance.toFixed(1) + (ApplicationState.distanceUnits === 'miles' ? 'mi' : 'km'),
                    state: 'default',
                });
            }
        }

        return indicators;
    }

    public static getOccurrencesByJobId(jobId: string) {
        return Data.all<JobOccurrence>('joboccurrences', undefined, { jobId });
    }

    public static getLatestNotDoneOccurrenceByJobId(jobId: string, excludeOccId?: string) {
        let occurrences = Data.all<JobOccurrence>('joboccurrences', undefined, { jobId, status: JobOccurrenceStatus.NotDone }).slice();
        if (!occurrences.length) return;
        if (excludeOccId) occurrences = occurrences.filter(e => e._id !== excludeOccId);
        return occurrences.sort((a, b) => {
            const aDate = a.isoPlannedDate || a.isoDueDate;
            const bDate = b.isoPlannedDate || b.isoDueDate;
            return aDate > bDate ? -1 : 1;
        })[0];
    }

    public static getOrCreateJobOccurence(occurrenceId: string) {
        const occurrence = Data.get<JobOccurrence>(occurrenceId);
        if (occurrence) return occurrence;

        const jobId = occurrenceId.slice(0, 36);
        const job = JobService.getJob(undefined, jobId);
        if (!job) throw 'Job not found';
        const isoDueDate = occurrenceId.slice(36, 46);

        return new JobOccurrence(job, isoDueDate);
    }
}
