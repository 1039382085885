import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { Api } from '../../Server/Api';
import { t } from '../../t';

export const isICalUrl = async (
    url?: string
): Promise<{ success: true } | { success: false; error: TranslationKey; params?: Record<string, string> }> => {
    try {
        const response = await Api.post<{ success: true } | { success: false; error: TranslationKey; params?: Record<string, string> }>(
            null,
            '/api/ical/validate',
            { url }
        );

        if (!response?.data) return { success: false, error: 'ical-validation.unexpected-error' };

        if (response.data.success) return response.data;

        const error = t(response.data.error, response.data.params);
        return { success: false, error };
    } catch (error) {
        return { success: false, error: 'ical-validation.unexpected-error' };
    }
};
