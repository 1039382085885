import type { Customer, Job, Tag, Transaction } from '@nexdynamic/squeegee-common';
import { TagType } from '@nexdynamic/squeegee-common';

export class ImportValidator {
    public static verifyCSVHeaders(
        recordKeys: Array<string>,
        requiredColoumns: Array<string>
    ): { errors: Array<string>; isValid: boolean } {
        const columns = requiredColoumns.slice(0);
        const returnValue: { errors: Array<string>; isValid: boolean } = { errors: [], isValid: true };
        for (let i = 0; i < recordKeys.length; i++) {
            const key = recordKeys[i].trim();
            const index = columns.indexOf(key);
            if (index > -1) columns.splice(index, 1);
        }
        //If there are still coloumns that are required
        if (columns.length) {
            returnValue.errors = returnValue.errors.concat(columns);
            returnValue.isValid = false;
        }
        return returnValue;
    }

    public static validateOutput(
        customers: Array<Customer>,
        transactions: Array<Transaction>,
        rounds: { [id: string]: Tag },
        services?: { [id: string]: Tag }
    ): { errors: Array<string>; isValid: boolean } {
        let errors: Array<string> = [];
        const roundsArray = Object.keys(rounds).map(key => rounds[key]);
        if (services) {
            const servicesArray = Object.keys(services).map(key => services[key]);
            errors = errors.concat(ImportValidator.validateServices(servicesArray));
        }
        errors = errors.concat(ImportValidator.validateCustomers(customers));
        errors = errors.concat(ImportValidator.validateTransactions(transactions));
        errors = errors.concat(ImportValidator.validateRounds(roundsArray));
        return { errors: errors, isValid: errors.length === 0 };
    }

    private static validateCustomers(customers: Array<Customer>): Array<string> {
        let errors: Array<string> = [];
        for (let i = 0; i < customers.length; i++) {
            const customer = customers[i];
            try {
                if (!customer._id) errors.push('Customer id is undefined ');
                if (!customer.resourceType) errors.push('Customer resourceType is undefined');
                if (customer.resourceType !== 'customers') errors.push('Customer resourceType is incorrect');
                if (customer.name === undefined) errors.push('Customer name is undefined');
                if (typeof customer.name !== 'string') errors.push('Customer name is not of type string');
                if (customer.address === undefined || !customer.address.addressDescription) errors.push('Customer address is undefined');
                if (typeof customer.address.addressDescription !== 'string')
                    errors.push('Customer address description is not of type string');
                if (!customer.jobs || typeof customer.jobs !== 'object') errors.push('Customer jobs is not of type object');
                if (customer.jobs && typeof customer.jobs === 'object') {
                    const jobErrors = ImportValidator.validateJobs(customer.jobs);
                    if (jobErrors && jobErrors.length) {
                        errors.push(`Errors in jobs for ${customer.name}`);
                        errors = errors.concat(jobErrors);
                    }
                }
            } catch (error) {
                errors.push('Customer validation Failed with the following error ' + error);
            }
        }
        return errors;
    }

    private static validateJobs(jobs: { [jobId: string]: Job }): Array<string> {
        let errors: Array<string> = [];
        const keys = Object.keys(jobs);

        for (let i = 0; i < keys.length; i++) {
            const job = jobs[keys[i]];
            if (!job) errors.push('Job is undefined');
            else {
                try {
                    if (!job._id) errors.push('id is undefined ');
                    if (typeof job._id !== 'string') errors.push('id is not of type string');
                    if (!job.customerId) errors.push('customerId is undefined');
                    if (typeof job.customerId !== 'string') errors.push('customerId is not of type string');
                    if (!job.location) errors.push('location is undefined');
                    if (!job.location || !job.location.addressDescription) errors.push('address description is undefined');
                    if (isNaN(job.price || 0)) {
                        errors.push('price is not of type number');
                    }
                    if (job.frequencyInterval !== undefined && isNaN(job.frequencyInterval))
                        errors.push('frequencyInterval is not of type number');
                    if (isNaN(job.frequencyType)) errors.push('frequencyType is not of type number');
                    if (job.frequencyWeekOfMonth !== undefined && isNaN(job.frequencyWeekOfMonth))
                        errors.push('frequencyWeekOfMonth is not of type number');
                    if (
                        job.frequencyDayOfWeek !== undefined &&
                        isNaN(Array.isArray(job.frequencyDayOfWeek) ? job.frequencyDayOfWeek[0] : job.frequencyDayOfWeek)
                    )
                        errors.push('frequencyDayOfWeek is not of type number');
                    if (job.frequencyDayOfWeek !== undefined && (job.frequencyDayOfWeek as number) === 0)
                        errors.push('0 is not a valid frequencyDayOfWeek');
                    if (!job.rounds) errors.push('Job Rounds is undefined');
                    if (job.rounds && !Array.isArray(job.rounds)) errors.push('Job Rounds is not of type array');
                    if (job.rounds && Array.isArray(job.rounds)) errors = errors.concat(ImportValidator.validateRounds(job.rounds));
                    if (!job.services) errors.push('Job services is undefined');
                    if (job.services && !Array.isArray(job.services)) errors.push('Job services is not of type array');
                    if (job.services && Array.isArray(job.services)) errors = errors.concat(ImportValidator.validateServices(job.services));
                } catch (error) {
                    errors.push('Job Validation Failed with the following error ' + error);
                }
            }
        }
        return errors;
    }

    private static validateRounds(rounds: Array<Tag>): Array<string> {
        const errors = [];
        for (let i = 0; i < rounds.length; i++) {
            const round = rounds[i];
            if (!round) errors.push('Round is undefined');
            else {
                try {
                    if (!round._id) errors.push('round Id is undefined ');
                    if (typeof round._id !== 'string') errors.push('round Id is not of type string');
                    if (round.resourceType !== 'tags') errors.push('round resourceType is not of type tags');
                    if (!round.description) errors.push('round description is undefined');
                    if (typeof round.description !== 'string') errors.push('round description is not of type string');
                    if (typeof round.type !== 'number') errors.push('round type is not of type number');
                    if (typeof round.type === 'number' && round.type !== TagType.ROUND) errors.push('round type is not of type ROUND');
                } catch (error) {
                    errors.push('Round Validation Failed with the following error ' + error);
                }
            }
        }
        return errors;
    }

    private static validateServices(services: Array<Tag>): Array<string> {
        const errors = [];

        for (let i = 0; i < services.length; i++) {
            const service = services[i];
            if (!service) errors.push('service is undefined');
            else {
                try {
                    if (!service._id) errors.push('service Id is undefined ');
                    if (typeof service._id !== 'string') errors.push('service Id is not of type string');
                    if (service.resourceType !== 'tags') errors.push('service resourceType is not of type tags');
                    if (!service.description) errors.push('service description is undefined');
                    if (typeof service.description !== 'string') errors.push('service description is not of type string');
                    if (typeof service.type !== 'number') errors.push('service type is not of type number');
                    if (typeof service.type === 'number' && service.type !== TagType.SERVICE)
                        errors.push('service type is not of type SERVICE');
                } catch (error) {
                    errors.push('Service Validation Failed with the following error ' + error);
                }
            }
        }
        return errors;
    }

    private static validateTransactions(transactions: Array<Transaction>): Array<string> {
        const errors = [];
        for (let i = 0; i < transactions.length; i++) {
            const transaction = transactions[i];
            if (!transaction) errors.push('transaction is undefined');
            else {
                try {
                    if (!transaction._id) errors.push('transaction Id is undefined ');
                    if (typeof transaction._id !== 'string') errors.push('transaction Id is not of type string');
                    if (!transaction.resourceType) errors.push('transaction resourceType is undefined');
                    if (transaction.resourceType !== 'transactions') errors.push('transaction resourceType is not of type transactions');
                    if (isNaN(transaction.amount)) {
                        errors.push('transaction amount is not of type number');
                    }
                    if (!transaction.customerId || typeof transaction.customerId !== 'string')
                        errors.push('transaction customerId is incorrect');
                } catch (error) {
                    errors.push('Transaction Validation Failed with the following error ' + error);
                }
            }
        }
        return errors;
    }
}
