import type { FrequencyData, LngLat, Tag } from '@nexdynamic/squeegee-common';
import { DayOfWeek, FrequencyBuilder, FrequencyType, FrequencyUnit, FrequencyUtils } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { LocationUtilities } from '../../Location/LocationUtilities';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { RoundService } from '../../Rounds/RoundService';
import type { JobGroupData } from '../../Tags/TagData';
import { CustomDialog } from '../CustomDialog';
import { isICalUrl } from './isICalUrl';

export class FrequencyPickerDialog extends CustomDialog<FrequencyData> {
    protected frequencyIntervals = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
        36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
    ];

    protected frequencyTypes = FrequencyUtils.getFrequencies();
    protected frequencyUnits = FrequencyUtils.getFrequencyUnits();
    protected frequencyUnit: FrequencyUnit = FrequencyUnit.Week;
    protected frequencySubInterval: number = DayOfWeek.Monday;
    protected frequencySubIntervals: Array<{ value: number; text: string; selected: boolean }> = [];
    protected frequencySubUnits: Array<{ value: FrequencyUnit; text: string }> = [
        { value: FrequencyUnit.Day, text: 'day' },
        { value: FrequencyUnit.Week, text: 'week' },
    ];
    protected frequencySubUnit: FrequencyUnit = FrequencyUnit.Day;
    protected previousFrequencySubUnit: FrequencyUnit = FrequencyUnit.Week;
    protected daysOfWeek: Array<{ value: number; text: string }> = [];
    protected showMonthOptions = false;
    protected showMonthDayOfWeek = false;
    protected monthlyDayOfWeek: DayOfWeek = DayOfWeek.Monday;
    protected frequencyTypesNonRecurring = this.frequencyTypes.filter(type => {
        return type.value !== FrequencyType.NoneRecurring;
    });
    protected originalFirstDate: string | undefined;
    protected originalFirstScheduledDate: string;

    protected interval: number;
    protected type: FrequencyType;
    protected _range: number;

    protected get range() {
        return this._range;
    }

    protected set range(value: number) {
        this._range = Number(value);
        this.initFrequencySubInterval();
    }

    private _firstScheduledDate: string;
    protected get firstScheduledDate() {
        return this._firstScheduledDate;
    }
    protected set firstScheduledDate(value: string) {
        this._firstScheduledDate = value;
        this.initFrequencySubInterval();
    }
    protected firstDateDiffersFromSchedule: boolean;
    protected firstDate: string | undefined;
    protected jobRecurs = true;

    protected frequencyData: FrequencyData;
    protected jobGroups: Array<JobGroupData>;

    public selectedJobGroup: JobGroupData | '' = '';
    protected selectedJobGroupChanged() {
        if (!this.selectedJobGroup || !this.selectedJobGroup.tag || !this.selectedJobGroup.tag.frequencyData) {
            this.selectedJobGroup = '';
            return;
        }
        this.frequencyData = this.selectedJobGroup.tag.frequencyData;
        this.type = this.frequencyData.type;
        this.interval = this.frequencyData.interval;

        if (this.frequencyData.firstScheduledDate) {
            const freq = FrequencyBuilder.getFrequency(
                this.frequencyData.interval,
                this.frequencyData.type,
                this.frequencyData.firstScheduledDate,
                this.frequencyData.dayOfMonth,
                this.frequencyData.weekOfMonth,
                this.frequencyData.dayOfWeek
            );
            const nextDate = freq.getNextDate();
            if (!nextDate) throw 'There is no future.';

            this.firstScheduledDate = nextDate;
            this.originalFirstScheduledDate = this.frequencyData.firstScheduledDate;
        }
        this.initFrequencySubInterval();
    }
    protected get menuTitle() {
        switch (this.mode) {
            case 'frequency':
                return 'Select frequency';
            case 'one-off':
                return 'Select appointment date';
            case 'external':
                return 'Enter external calendar url';
        }
    }
    public dateRequired = true;
    protected showExternal = false;

    protected showDateRequiredToggle: boolean;

    protected showScheduleRangeInput: boolean;
    protected scheduleUsesRange: boolean;
    protected showFrequency: boolean;
    protected showOneOff: boolean;
    protected showJobGroups: boolean;
    protected mode: 'frequency' | 'one-off' | 'external';
    protected distanceSortLngLat?: LngLat;
    protected round?: Tag;
    protected showDateSelectionFields: boolean;
    protected defaultShowDateRequiredToggle: boolean;
    protected hasPreviouslySelectedDate: boolean;
    protected matchingText?: Array<string>;
    protected defaultDateRequired: boolean;
    protected quoteConversion: boolean;
    protected applyRange: boolean;
    protected rangeText?: string;
    protected isNewFrequency: boolean;

    constructor({
        frequencyData,
        showFrequency = true,
        showOneOff = true,
        showJobGroups = true,
        mode = 'frequency',
        distanceSortLngLat,
        round,
        showDateSelectionFields = true,
        defaultShowDateRequiredToggle = true,
        hasPreviouslySelectedDate = false,
        matchingText,
        defaultDateRequired = true,
        quoteConversion = false,
        applyRange = false,
        rangeText,
        isNewFrequency = true,
    }: {
        frequencyData?: FrequencyData;
        showFrequency?: boolean;
        showOneOff?: boolean;
        showJobGroups?: boolean;
        mode?: 'frequency' | 'one-off' | 'external';
        distanceSortLngLat?: LngLat;
        round?: Tag;
        showDateSelectionFields?: boolean;
        defaultShowDateRequiredToggle?: boolean;
        hasPreviouslySelectedDate?: boolean;
        matchingText?: Array<string>;
        defaultDateRequired?: boolean;
        quoteConversion?: boolean;
        applyRange?: boolean;
        rangeText?: string;
        isNewFrequency?: boolean;
    }) {
        super('frequencyPickerDialog', './FrequencyPicker/FrequencyPickerDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'frequency-picker-dialog has-header-content',
            isSecondaryView: true,
        });

        this.showFrequency = showFrequency;
        this.showOneOff = showOneOff;
        this.showJobGroups = showJobGroups;
        this.mode = mode;
        this.distanceSortLngLat = distanceSortLngLat;
        this.round = round;
        this.showDateSelectionFields = showDateSelectionFields;
        this.defaultShowDateRequiredToggle = defaultShowDateRequiredToggle;
        this.hasPreviouslySelectedDate = hasPreviouslySelectedDate;
        this.matchingText = matchingText;
        this.defaultDateRequired = defaultDateRequired;
        this.quoteConversion = quoteConversion;
        this.applyRange = applyRange;
        this.rangeText = rangeText;
        this.isNewFrequency = isNewFrequency;

        if (frequencyData) {
            switch (frequencyData.type) {
                case FrequencyType.NoneRecurring:
                    this.mode = 'one-off';
                    break;
                case FrequencyType.ExternalCalendar:
                    this.mode = 'external';
                    break;
            }
        }

        if (ApplicationState.hasUltimateOrAbove) {
            this.showExternal = true;
        }

        this.showDateRequiredToggle = defaultShowDateRequiredToggle;

        if (!frequencyData) {
            this.frequencyData = {
                type: FrequencyType.NoneRecurring,
                interval: 0,
                dayOfMonth: 1,
                dayOfWeek: 1,
                firstScheduledDate: moment().format('YYYY-MM-DD'),
                weekOfMonth: 1,
            };
        } else {
            this.frequencyData = frequencyData;
        }

        this.showScheduleRangeInput = ApplicationState.stateFlags.devMode && (!quoteConversion || this.applyRange);
        this.initDaysOfWeek();
        this.initFrequencyData();

        this.switchMode(this.mode);
    }

    async init() {
        this.selectedJobGroupChanged();
        this.firstScheduledDate = this.frequencyData.firstScheduledDate || moment().format('YYYY-MM-DD');
    }

    public async ok(result?: FrequencyData | undefined): Promise<void> {
        try {
            new LoaderEvent(true, false, 'ical-validation.checking');
            if (this.mode === 'external') {
                const url = this.frequencyData.externalCalendarUrl;
                const checkiCalUrl = await isICalUrl(url);
                if (!checkiCalUrl.success) {
                    new LoaderEvent(false);
                    new NotifyUserMessage(checkiCalUrl.error);
                    return;
                }
            }

            await super.ok(result);
        } finally {
            new LoaderEvent(false);
        }
    }

    private initFrequencyData() {
        this.type = this.frequencyData.type;
        this.interval = this.frequencyData.interval;
        this.range = this.frequencyData.range || 0;

        if (this.frequencyData.firstScheduledDate) {
            this.firstScheduledDate = this.frequencyData.firstScheduledDate;
            this.originalFirstScheduledDate = this.frequencyData.firstScheduledDate;
        }

        this.firstDateDiffersFromSchedule = this.firstDate ? true : false;

        if (this.hasPreviouslySelectedDate) this.dateRequired = true;
        else if (this.isNewFrequency) this.dateRequired = this.defaultDateRequired;
        else this.dateRequired = this.frequencyData.firstScheduledDate ? true : false;
    }

    private async initJobGroups() {
        let jobGroups = ApplicationState.getSetting('global.use-nearest-job-in-round', true)
            ? await RoundService.getRoundsData()
            : await RoundService.getRoundsData(undefined, this.distanceSortLngLat);

        for (let i = 0; i < jobGroups.length; i++) {
            const tag = jobGroups[i];

            if (this.distanceSortLngLat && tag.lngLat) {
                tag.distance = LocationUtilities.getDistanceBetweenPoints(tag.lngLat, this.distanceSortLngLat, 'miles');
            }
            if (this.round && tag.tag._id === this.round._id) this.selectedJobGroup = tag;
        }

        jobGroups = jobGroups
            .filter(jg => jg.tag.frequencyData !== undefined)
            .sort((tagA, tagB) => {
                return (tagA.distance || 10000) - (tagB.distance || 10000);
            });

        const matchingTexts = this.matchingText?.map(x => x.toLowerCase()) || [];
        if (matchingTexts.length) {
            const matched = [] as Array<JobGroupData>;
            for (const matchingText of matchingTexts) {
                let count = jobGroups.length;
                while (count--) {
                    const jobGroupData = jobGroups[count];
                    if (!jobGroupData.matchingText || jobGroupData.matchingText.indexOf(matchingText) === -1) continue;

                    jobGroupData.tag.description += ` (matches ${matchingText.toUpperCase()})`;

                    matched.push(jobGroupData);
                    jobGroups.splice(count, 1);
                }
            }
            if (matched.length > 0) jobGroups = matched.concat(jobGroups);
        }

        this.jobGroups = jobGroups;
    }

    protected setLimitedFirstScheduledDate() {
        if (this.frequencyUnit === FrequencyUnit.Month && this.frequencySubUnit === FrequencyUnit.Day) {
            const selectedDate = moment(this.firstScheduledDate, 'YYYY-MM-DD');
            // if the date picker has updated the date and the picker is set to date of the month, max it to 28th
            if (selectedDate.date() > 28) {
                selectedDate.date(28);
            }
            this.firstScheduledDate = selectedDate.format('YYYY-MM-DD');
        }
        this.updateFirstScheduledDate(true);
    }

    protected initDaysOfWeek() {
        for (let i = 1; i < 8; i++) {
            this.daysOfWeek.push({ value: i, text: moment().isoWeekday(i).format('dddd') });
        }
    }

    protected initFrequencySubInterval() {
        if (this.type === FrequencyType.MonthlyDayOfWeek) {
            this.frequencyUnit = FrequencyUnit.Month;
            this.frequencySubUnit = FrequencyUnit.Week;
            this.frequencySubInterval = this.frequencyData.weekOfMonth
                ? this.frequencyData.weekOfMonth
                : FrequencyUtils.getWeekOfMonth(moment(this.firstScheduledDate, 'YYYY-MM-DD'));
        } else if (this.type === FrequencyType.DayOfMonth) {
            this.frequencyUnit = FrequencyUnit.Month;
            this.frequencySubUnit = FrequencyUnit.Day;
            this.frequencySubInterval = this.frequencyData.dayOfMonth
                ? this.frequencyData.dayOfMonth
                : moment(this.firstScheduledDate, 'YYYY-MM-DD').date();
        } else if (this.type === FrequencyType.Weeks) {
            this.frequencySubUnit = FrequencyUnit.Day;
        } else if (this.type === FrequencyType.Days) {
            this.frequencyUnit = FrequencyUnit.Day;
        }
        if (this.firstDateDiffersFromSchedule && !this.firstDate) {
            this.initFirstDate();
        }
        if (this.type !== FrequencyType.NoneRecurring) this.updateFrequencySubUnit();
    }

    private initFirstDate() {
        if (this.originalFirstDate) this.firstDate = this.originalFirstDate;
        const newFirstDate = this.firstDate ? moment(this.firstDate, 'YYYY-MM-DD') : moment();
        const jobDate = moment(this.firstScheduledDate, 'YYYY-MM-DD');
        while (newFirstDate.isSame(jobDate, 'day')) {
            newFirstDate.subtract(1, 'day');
        }
        this.firstDate = newFirstDate.format('YYYY-MM-DD');
    }

    protected updateFirstScheduledDate(newDateSelected = false) {
        if (this.frequencyUnit === FrequencyUnit.Day) {
            this.updateDayOfWeekByDate();
        } else if (this.frequencyUnit === FrequencyUnit.Week) {
            if (!newDateSelected) {
                this.updateDateByDayOfWeek();
            } else {
                this.updateDayOfWeekByDate();
            }
        } else if (this.frequencyUnit === FrequencyUnit.Month && this.frequencySubUnit === FrequencyUnit.Day) {
            // need to update the frequency sub interval first as it is being shared with week x of month
            // and bring date to max 28th if using this method
            let dateReduced = false;
            const currentDate = moment(this.firstScheduledDate, 'YYYY-MM-DD');
            if (currentDate.date() > 28) {
                currentDate.date(28);
                this.firstScheduledDate = currentDate.format('YYYY-MM-DD');
                dateReduced = true;
            }
            //change the frequency sub interval to day of month if coming in from weekly mode, otherwise leave alone
            if (this.previousFrequencySubUnit === FrequencyUnit.Week || dateReduced) this.frequencySubInterval = currentDate.date();
            if (!newDateSelected) {
                this.firstScheduledDate = moment(this.firstScheduledDate, 'YYYY-MM-DD')
                    .date(this.frequencySubInterval)
                    .format('YYYY-MM-DD');
            } else {
                this.frequencySubInterval = currentDate.date();
            }
            this.previousFrequencySubUnit = FrequencyUnit.Day;
        } else if (this.frequencyUnit === FrequencyUnit.Month && this.frequencySubUnit === FrequencyUnit.Week) {
            // first fresh the frequency sub interval as it is shared with days of the month
            if (this.previousFrequencySubUnit === FrequencyUnit.Day)
                this.frequencySubInterval = FrequencyUtils.getWeekOfMonth(moment(this.firstScheduledDate, 'YYYY-MM-DD'));
            if (newDateSelected) {
                this.updateDayOfWeekByDate();
            } else {
                this.updateDateByDayOfWeek();
            }
            this.previousFrequencySubUnit = FrequencyUnit.Week;
        }
    }

    protected updateDayOfWeekByDate = () => {
        //someone changed the date directly so update the week of month and day of week if necessary
        const currentDate = moment(this.firstScheduledDate, 'YYYY-MM-DD');
        const currentDateDayOfWeek = currentDate.isoWeekday();
        if (this.frequencyUnit === FrequencyUnit.Month && this.frequencySubUnit === FrequencyUnit.Week) {
            this.frequencySubInterval = FrequencyUtils.getWeekOfMonth(currentDate);
            this.monthlyDayOfWeek = currentDateDayOfWeek;
        } else if (this.frequencyUnit === FrequencyUnit.Week) {
            this.frequencySubInterval = currentDateDayOfWeek;
        }
    };

    protected updateDateByDayOfWeek = () => {
        let selectedDayOfWeek: number | undefined;
        const currentDate = moment(this.firstScheduledDate, 'YYYY-MM-DD');
        const currentDateDayOfWeek = currentDate.isoWeekday();
        // someone clicked the day of week rather than update a date directly
        if (this.frequencyUnit === FrequencyUnit.Month && this.frequencySubUnit === FrequencyUnit.Week) {
            const startOfMonth = moment(this.firstScheduledDate).date(1).format('YYYY-MM-DD');
            this.firstScheduledDate = FrequencyUtils.getDateFromWeekOfMonthAndDay(
                this.frequencySubInterval,
                this.monthlyDayOfWeek,
                startOfMonth
            );
        } else if (this.frequencyUnit === FrequencyUnit.Week) {
            selectedDayOfWeek = this.frequencySubInterval;
            if (currentDateDayOfWeek !== selectedDayOfWeek) {
                const daysToAdd = selectedDayOfWeek - currentDateDayOfWeek;
                const newDate = moment(this.firstScheduledDate, 'YYYY-MM-DD').add(daysToAdd, 'days');
                this.firstScheduledDate = newDate.format('YYYY-MM-DD');
            }
        }
    };

    protected updateFrequencySubUnit() {
        const frequencySubIntervals: Array<{ value: number; text: string; selected: boolean }> = [];
        const date = this.firstScheduledDate;
        if (this.frequencyUnit === FrequencyUnit.Week) {
            this.frequencySubUnit === FrequencyUnit.Day;
            for (let i = 1; i < 8; i++) {
                frequencySubIntervals.push({
                    value: i,
                    text: moment().isoWeekday(i).format('dddd') + 's',
                    selected: this.frequencySubInterval === i,
                });
            }
            this.frequencySubInterval = moment(date, 'YYYY-MM-DD').isoWeekday();
            this.type = FrequencyType.Weeks;
            this.showMonthOptions = false;
            this.showMonthDayOfWeek = false;
        }
        if (this.frequencyUnit === FrequencyUnit.Month && this.frequencySubUnit === FrequencyUnit.Day) {
            for (let i = 1; i < 29; i++) {
                frequencySubIntervals.push({ value: i, text: this.getOrdinalSuffixForNumber(i), selected: false });
            }
            this.frequencySubInterval = moment(date, 'YYYY-MM-DD').date() > 28 ? 28 : moment(date, 'YYYY-MM-DD').date();
            this.type = FrequencyType.DayOfMonth;
            this.showMonthOptions = true;
            this.showMonthDayOfWeek = false;
        }
        if (this.frequencyUnit === FrequencyUnit.Month && this.frequencySubUnit === FrequencyUnit.Week) {
            for (let i = 0; i < FrequencyUtils.weekValues.length; i++) {
                frequencySubIntervals.push({
                    value: FrequencyUtils.weekValues[i],
                    text: FrequencyUtils.suffixDateWeekNumber(FrequencyUtils.weekValues[i]),
                    selected: false,
                });
            }
            this.frequencySubInterval = FrequencyUtils.getWeekOfMonth(moment(date, 'YYYY-MM-DD'));
            this.type = FrequencyType.MonthlyDayOfWeek;
            this.showMonthOptions = true;
            this.showMonthDayOfWeek = true;
            this.monthlyDayOfWeek = moment(date, 'YYYY-MM-DD').isoWeekday();
        }
        if (this.frequencyUnit === FrequencyUnit.Day) {
            this.type = FrequencyType.Days;
            this.showMonthOptions = false;
            this.showMonthDayOfWeek = false;
        }
        this.frequencySubIntervals = frequencySubIntervals;
    }

    protected getOrdinalSuffixForNumber(selectedNumber: number) {
        const endOfNumberUnit = selectedNumber % 10;
        const endOfNumberTens = selectedNumber % 100;
        if (endOfNumberUnit === 1 && endOfNumberTens !== 11) return selectedNumber.toString() + 'st';
        if (endOfNumberUnit === 2 && endOfNumberTens !== 12) return selectedNumber.toString() + 'nd';
        if (endOfNumberUnit === 3 && endOfNumberTens !== 13) return selectedNumber.toString() + 'rd';
        return selectedNumber.toString() + 'th';
    }

    protected getFrequencyInfo(requiredUnit: FrequencyUnit) {
        const frequencyUnitInfoArray = this.frequencyUnits.filter(unit => {
            return unit.value === requiredUnit;
        });
        if (frequencyUnitInfoArray && frequencyUnitInfoArray.length) return frequencyUnitInfoArray[0];
    }

    protected get isRepeatingWeeklyOrMonthly(): boolean | undefined {
        return this.type === FrequencyType.MonthlyDayOfWeek || this.type === FrequencyType.DayOfMonth || this.type === FrequencyType.Weeks;
    }

    protected selectFrequency = () => {
        this.frequencyData.interval = this.interval;
        this.frequencyData.firstScheduledDate = this.firstScheduledDate;
        this.frequencyData.dayOfMonth = undefined;
        this.frequencyData.dayOfWeek = undefined;
        this.frequencyData.type = this.type;
        this.frequencyData.range = this.range;
        if (
            this.frequencyData.type > FrequencyType.NoneRecurring &&
            this.frequencyData.type < FrequencyType.ExternalCalendar &&
            this.frequencyData.interval - this.frequencyData.range < 1 &&
            !this.rangeText
        )
            return new NotifyUserMessage('frequency-picker.range-greater-than-interval-error');
        if (this.type === FrequencyType.MonthlyDayOfWeek) {
            this.frequencyData.dayOfWeek = this.monthlyDayOfWeek;
            this.frequencyData.weekOfMonth = this.frequencySubInterval;
        } else if (this.type === FrequencyType.Weeks) {
            this.frequencyData.dayOfWeek = this.frequencySubInterval;
        } else if (this.type === FrequencyType.DayOfMonth) {
            this.frequencyData.dayOfMonth = this.frequencySubInterval;
        }

        this.ok(this.frequencyData);
    };

    protected get frequencyText() {
        const frequencyTypeFilter = this.frequencyTypes.filter(frequencyType => {
            return frequencyType.value === this.type;
        });
        if (frequencyTypeFilter && frequencyTypeFilter.length) {
            const frequencyType = frequencyTypeFilter[0];
            return this.frequencyData.interval === 1 ? frequencyType.text : frequencyType.pluralText;
        }
    }

    protected get frequencyTextOptions() {
        return this.frequencyTypes.filter(frequencyType => {
            return frequencyType.value === this.type;
        });
    }

    @computedFrom('firstScheduledDate')
    protected get formattedFirstScheduledDate() {
        return moment(this.firstScheduledDate).format('ddd MMM D YYYY');
    }

    protected async chooseNextScheduledDate() {
        const datePicker = new DateTimePicker(false, this.firstScheduledDate);
        datePicker.init();
        await datePicker.open();
        if (!datePicker.canceled) {
            this.firstScheduledDate = datePicker.selectedDate;
            this.setLimitedFirstScheduledDate();
            this.updateFirstScheduledDate(true);
        }
    }

    protected async chooseJobDate() {
        const datePicker = new DateTimePicker(false, this.firstScheduledDate);
        datePicker.init();
        await datePicker.open();
        if (!datePicker.canceled) {
            this.firstScheduledDate = datePicker.selectedDate;
        }
    }

    protected switchMode(mode: 'one-off' | 'frequency' | 'external') {
        this.mode = mode;
        switch (this.mode) {
            case 'frequency':
                this.initFrequencyData();

                this.showDateRequiredToggle = this.defaultShowDateRequiredToggle;
                if (this.originalFirstDate && this.originalFirstDate !== this.firstScheduledDate) {
                    this.firstDate = this.originalFirstDate;
                    this.firstDateDiffersFromSchedule = true;
                } else {
                    this.firstDate = undefined;
                    this.firstDateDiffersFromSchedule = false;
                }
                this.interval = this.frequencyData.interval ? this.frequencyData.interval : 4;
                this.type = this.frequencyData.type ? this.frequencyData.type : 2;

                this.initFrequencySubInterval();
                this.initJobGroups();
                break;
            case 'one-off':
                this.showDateRequiredToggle = this.defaultShowDateRequiredToggle;
                if (this.firstDate) {
                    this.firstDate = undefined;
                    this.firstDateDiffersFromSchedule = false;
                }
                this.initOneOffData();
                break;
            case 'external':
                this.initExternalCalendarData();
                break;
        }
    }

    private initOneOffData() {
        this.type = FrequencyType.NoneRecurring;
        this.interval = 0;
        this.frequencyUnit === undefined;
        this.frequencySubInterval = 0;
    }

    private initExternalCalendarData() {
        if (this.firstDate) {
            this.firstDate = undefined;
            this.firstDateDiffersFromSchedule = false;
        }
        this.type = FrequencyType.ExternalCalendar;
        this.interval = 0;
        this.frequencyUnit === undefined;
        this.frequencySubInterval = 0;
        this.dateRequired = false;
        this.showDateRequiredToggle = false;
    }

    protected updateDateFromFrequencySubInterval() {
        this.updateFirstScheduledDate();
    }
}
